const comparedKeys = [
  'Account.attributes.displayedAccounts.value',
  'Account.attributes.priority.value',
  'Account.attributes.inCRM.value',
  'Account.attributes.industry.value',
  'Account.attributes.employees.value',
  'Account.attributes.annualRevenue.value',
  'Account.attributes.technology.value',
  'Account.attributes.location.value',
  'Account.insights.suitability.value',
  'Account.insights.similarWins.value',
  'Account.insights.upcomingRenewalDate.value'
];


type NestedObject = { [key: string]: any };

function getValueByKeyPath(obj: NestedObject, keyPath: string): any {
    return keyPath.split('.').reduce((acc, key) => acc && acc[key], obj);
}

type ComparePayload = any[] | { min: number, max: number } | string | number | null | undefined;

function compareValues(value1: ComparePayload, value2: ComparePayload): boolean {
    // Helper function to check if the value is an empty array like ['']
    const isEmptyArray = (value: ComparePayload) => Array.isArray(value) && ((value.length === 1 && value[0] === '') || !value.length) || value === ''

    // Check if either value is an empty array [''] and the other is null or undefined
    if ((isEmptyArray(value1) && (value2 === null || value2 === undefined)) ||
        (isEmptyArray(value2) && (value1 === null || value1 === undefined))) {
        return true;
    }

    // For non-empty arrays, objects, or direct comparisons
    if (Array.isArray(value1) && Array.isArray(value2)) {
        return JSON.stringify(value1) === JSON.stringify(value2); // Compare arrays by their content
    } else if (typeof value1 === 'object' && typeof value2 === 'object') {
      if ((value1 as any).min === (value2 as any).min && (value1 as any).max === (value2 as any).max) return true;
        return JSON.stringify(value1) === JSON.stringify(value2); // Compare objects by their content
    } else {
        return value1 === value2; // Direct comparison for strings, numbers, or other types
    }
}


export function compareFilterStatus(filter1: any, filter2: any): boolean {
  let isMatch = true;
  for (let key of comparedKeys) {
    isMatch = compareValues(getValueByKeyPath(filter1, key), getValueByKeyPath(filter2, key))
    if (!isMatch) break;
  }
  return isMatch
}