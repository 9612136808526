import { CloseCircleOutlined } from '@ant-design/icons';
import { Box, Button, Modal, Stack, Typography } from '@mui/material';
import { CloseIcon } from 'assets/icons';
import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { csvToJson } from 'utils/file';


interface ColumnSelectorModalProps {
  isOpen: boolean;
  fileBody: any;
  onColumnNameSelected: (columnName: string) => void;
  onClose: () => void;
}


const ColumnHeader = ({ children }: any) => {
  return (
    <Box
      className='truncate'
      sx={{
        backgroundColor: '#E5EFF5',
        borderBottom: '1px solid #E5EFF5',
        p: '3px 5px',
        fontWeight: 'semibold',
        fontSize: '13px',
        maxWidth: '130px',
      }}>
      {children}
    </Box>
  )
}
const RowCell = ({ children }: any) => {
  return (
    <Box
      className='truncate'
      sx={{
        borderBottom: '1px solid #0802020f',
        fontSize: '13px',
        minHeight: '27.4px',
        p: '3px 5px',
        maxWidth: '130px',
        ':last-child': {
          borderBottom: 'none'
        }
      }}
    >
      {children}
    </Box>
  )
}

const ColumnPreview = ({ column, sampleRows, onSelect, isSelected }: any) => {

  return (
    <Stack
      onClick={() => onSelect(column)}
      sx={{
        flex: 1,
        cursor: 'pointer',
        userSelect: 'none',
        ':first-child': {
          borderTopLeftRadius: '5px',
          borderBottomLeftRadius: '5px',
        },
        ':last-child': {
          borderTopRightRadius: '5px',
          borderBottomRightRadius: '5px',
        },
        border: '1px solid #0802020f',
        ':hover': {
          borderRadius: '5px',
          borderColor: !isSelected ? 'lightgray' : ''
        },
        ...(isSelected ? {
          // borderRadius: '5px',
          borderColor: '#262626',
        } : {})
      }}
    >
      <ColumnHeader >{column}</ColumnHeader>
      {sampleRows.slice(0, 2).map((row: string) => <RowCell key={row}>{row}</RowCell>)}
    </Stack>
  )
}

const ColumnSelectorModal = ({ isOpen, onClose, fileBody, onColumnNameSelected }: ColumnSelectorModalProps) => {
  const { titles, content } = fileBody;
  const [selectedColumn, setSelectedColumn] = useState<string>();

  const onSelect = (col: string) => {
    setSelectedColumn((preCol) => preCol === col ? undefined : col)
  };

  const onSubmitSelection = () => {
    if (!selectedColumn) return;
    onColumnNameSelected(selectedColumn);
    onClose()
  }

  const hasSelection = Boolean(selectedColumn?.trim?.())

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{ p: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '12px',
          flexDirection: 'column',
          borderRadius: '15px',
          padding: '20px 40px',
          background: 'white',
        }}
      >
        <Stack direction='row' alignItems='center' justifyContent={'space-between'}>
          <Typography variant='h5'>
            Please select the columns that represent the your accounts name
          </Typography>
          <CloseCircleOutlined style={{ fontSize: '16px' }} onClick={onClose} />
        </Stack>
        <Box
          sx={{
            border: '1px solid #0802020f',
            width: '900px',
            maxHeight: '300px',
            marginTop: '10px',
            background: 'white',
            overflow: 'auto',
            borderRadius: '10px',
            display: 'flex',
            flexDirection: 'row'
          }}
        >
          {titles.map((titleKey: any) => {
            return (
              <ColumnPreview
                key={`${titleKey}_${titleKey === selectedColumn}`}
                isSelected={titleKey === selectedColumn}
                column={titleKey}
                onSelect={onSelect}
                sampleRows={content.map((obj: object) => obj[titleKey as keyof typeof obj])}
              />
            )
          })}
        </Box>
        <Stack sx={{ mt: '20px' }} direction='row' justifyContent={hasSelection ? 'space-between' : 'flex-end'} alignItems='center'>
          {hasSelection && (
            <Typography>
              We'll use the column <strong style={{ textDecoration: 'capitalize' }}>{selectedColumn}</strong>, if you are sure click Start processing?
            </Typography>
          )}
          <Button onClick={onSubmitSelection} variant={hasSelection ? 'contained' : 'outlined'}>
            Start processing
          </Button>
        </Stack>
      </Box>
    </Modal>
  )
}

export default ColumnSelectorModal


export const useCsvColumnSelector = () => {
  const [csvFileBody, setCsvFileBody] = React.useState<any>()
  const [isCsvPreviewOpen, setCsvPreviewOpenModal] = React.useState<boolean>(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const onCloseCsvPreview = () => {
    setCsvPreviewOpenModal(false)
  }

  const onFileUploaded = async (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log('Start processing the file ...')
    try {
      const { files } = event.target;
      if (!files || files.length === 0) {
        console.log('No file was uploaded')
        toast.warn('No file was uploaded')
        return;
      }
      const uploadedFile = files[0];
      console.time('Process CSV')
      const fileContent = await csvToJson(uploadedFile)
      console.timeEnd('Process CSV')
      console.log('Content', fileContent)
      setCsvFileBody(fileContent)
      setCsvPreviewOpenModal(true)
    } catch (error: any) {
      toast.error(error || 'Something went wrong')
    }
  }

  const onClickUpload = () => {
    inputRef.current?.click?.();
  }

  const renderInput = (
    <input
      key={`${new Date()}`}
      type="file"
      accept='.csv'
      onChange={onFileUploaded}
      ref={inputRef}
      style={{ display: 'none' }}
    />
  );


  return {
    renderInput,
    onClickUpload,
    fileBody: csvFileBody,
    isCsvPreviewOpen,
    onCloseCsvPreview
  }
}