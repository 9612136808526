import { Stack, Typography } from '@mui/material';
import React from 'react';


interface ProcessingMessageProps {
  remainingAccounts: number
}
const ProcessingMessage = (props: ProcessingMessageProps) => {
  const { remainingAccounts } = props
  return (
    <Stack
      sx={{
        padding: "20px",
        backgroundColor: "#ffffff",
        borderRadius: '10px',
        border: "1px solid #e0e0e0",
      }}
    >
      <Typography variant='body1'>
        We are processing the rest of the accounts ({remainingAccounts}), you will receive an email notification shortly once its ready.
      </Typography>
    </Stack>
  )
}

export default ProcessingMessage