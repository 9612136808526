import {
  TableHead as MuTableHead, Stack, SxProps, TableCell,
  TableRow,
} from "@mui/material";
import type { HeaderGroup } from "react-table";

import { DynFilterColumn } from "types/api";

import { HeaderSort } from "./Misc";

interface THeadProps {
  headerGroups: HeaderGroup[];
  showFiltersRow?: boolean;
  backgroundColor?: string;
  cellSX?: SxProps;
  dynColsDetails?: DynFilterColumn;
}

function TableHead(props: THeadProps) {
  const {
    headerGroups, backgroundColor, cellSX = {}, dynColsDetails = {},
  } = props;
  const changeSortableColumns = (column: any) => {
    const _column = { ...column };

    if (Object.keys(dynColsDetails).includes(_column.id)) {
      _column.disableSortBy = !dynColsDetails[column.id]?.sortable
      _column.getSortByToggleProps = () => ({});
    } else {
      _column.disableSortBy = true;
    }
    return _column;
  };

  return (
    <MuTableHead
      sx={{ background: "#FAFAFA", boxShadow: "-10px 0px 0px -9px rgba(0, 0, 0, 0.06)" }}
    >
      {headerGroups.map((headerGroup: HeaderGroup) => {
        const { headers, getHeaderGroupProps } = headerGroup;
        const headerProps = getHeaderGroupProps();
        return (
          <TableRow
            sx={{
              height: "40px",
            }}
            {...headerProps}
          >
            {headers.map(changeSortableColumns).map((column: any) => {
              const columnHeaderProps = column.getHeaderProps([
                { className: column.className, style: column.style },
              ]);
              const borderRight = typeof column.hasBorderRight === "function"
                ? column.hasBorderRight(headers.map((column) => column.id))
                : column.hasBorderRight;
              return (
                <TableCell
                  sx={{
                    color: "#aaa",
                    backgroundColor,
                    borderLeft: column.hasBorderLeft ? "2px solid #757575" : "none",
                    borderBottom: "2px solid #757575",
                    boxShadow: borderRight ? "rgba(0, 0, 0, 0.01) 0px 10px 5px 0px" : "none",
                    ...cellSX,
                  }}
                  key={`umbrella-header-cell-${column.accessor}`}
                  {...columnHeaderProps}
                >
                  <Stack
                    direction="row"
                    spacing={1.15}
                    alignItems="center"
                    sx={{ display: "inline-flex" }}
                  >
                    <HeaderSort column={column} sort />
                  </Stack>
                </TableCell>
              );
            })}
          </TableRow>
        );
      })}
    </MuTableHead>
  );
}

export default TableHead;
