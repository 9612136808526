import { LookUpDataSource } from "services/shooks/territoryFilters";
import { OrderDirectionEnum, TerritoryTableColumnsAccessors } from "types/account";

import { DynamicFilterT, VendorProduct } from ".";


export type TerritoryFilters = {
  searchQuery?: string;
  accountOwners?: string[] | null;
  dataSource: LookUpDataSource,
  pipelineAttributeFilters: PipelineAttributeFilters;
  firmographicFilters: FirmographicFilters;
  technographicFilters?: TechnographicFilters;
  offset: number;
  limit: number;
  accountStatus?: AccountStatus;
  orderBy?: TerritoryTableColumnsAccessors;
  orderDirection?: OrderDirectionEnum;
  pipelineSignals?: PipelineSignalEnums[];
  customFilters?: ICustomFilter[];
  accountIds?: string[];
  Account?: any
} & Partial<DynamicFilterT>;

export enum TerritoryFiltersEnums {
  searchQuery = "searchQuery",
  accountOwners = "accountOwners",
  pipelineAttributeFilters = "pipelineAttributeFilters",
  firmographicFilters = "firmographicFilters",
  technographicFilters = "technographicFilters",
  pipelineSignals = "pipelineSignals",
  customFilters = "customFilters",
  offset = "offset",
  limit = "limit",
  accountStatus = "accountStatus",
}

export interface AccountStatus {
  awareness?: number;
  activity?: number;
  engagement?: number;
  opportunities?: number;
}

enum AccountFitRank {
  Low = 1, // old value Low
  Mid = 2, // old value Mid
  High = 3, // old Value High
}

export enum PipelineSignalEnums {
  Strong = AccountFitRank.High,
  Medium = AccountFitRank.Mid,
  Low = AccountFitRank.Low,
}


enum AccountPriority {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
}

export enum AccountPriorityEnums {
  A = AccountPriority.A,
  B = AccountPriority.B,
  C = AccountPriority.C,
  D = AccountPriority.D,
}

export interface PipelineAttributeFilters {
  availableWhitespace?: RangeFilter;
  lastSalesActivity: RangeDateFilter;
  lastCustomerActivity: RangeDateFilter;
  nextRenewalDate: RangeFilter;
}

export interface RangeFilter<T = number> {
  min?: T;
  max: T;
}

export interface RangeDateFilter {
  startDate?: string | null;
  endDate?: string | null;
}
export interface HeadquarterAddress {
  country?: string;
  state?: string;
  city?: string;
}

export interface FirmographicFilters {
  annualRevenue: RangeFilter;
  employees: RangeFilter;
  industry?: string[] | null;
  headquarters?: HeadquarterAddress[];
}

export interface TechnographicFilters {
  ownedProducts?: VendorProduct[] | null;
}

export enum AccountStatusOnConditions {
  awareness = "There is recent buying intent and/or engagement with marketing campaigns",
  activity = "The SDR or Account Owner has made recent attempts to reach out",
  engagement = "The customer has recently engaged with the sales team",
  opportunities = "There is an open new business opportunity",
}

export enum AccountStatusOffConditions {
  awareness = "There are 0 recent marketing or intent activities",
  activity = "There are 0 recent SDR or Account Owner activities",
  engagement = "There are 0 responses from the customer",
  opportunities = "There are 0 new business opportunities",
}

export interface TerritoryFiltersDefaultValues {
  pipelineAttributeFilters?: PipelineAttributeDefaultFilters;
  firmographicFilters?: FirmographicDefaultFilters;
  technographicFilters?: TechnographicDefaultFilters;
  searchQuery?: string;
  pipelineSignals?: PipelineSignalEnums[];
  accountStatus?: AccountStatus;
  accountOwners?: string[] | null;
  customFilter?: ICustomFilter[];
}
export interface PipelineAttributeDefaultFilters {
  availableWhitespace?: RangeFilter;
  lastSalesActivity?: RangeDateFilter | string;
  lastCustomerActivity?: RangeDateFilter | string;
  nextRenewalDate?: RangeFilter | string;
}

export interface FirmographicDefaultFilters {
  annualRevenue?: RangeFilter | string;
  employees?: Partial<RangeFilter> | string;
  industry?: string[] | null;
  headquarters?: HeadquarterAddress[];
}

export interface TechnographicDefaultFilters {
  ownedProducts?: VendorProduct[] | null;
}

export interface ICustomFilter {
  id: string;
  fieldName: string;
  operator: CustomFilterOperatorEnum;
  value: string;
  valueType: string;
  label?: string;
}

export enum CustomFilterOperatorEnum {
  Contains = "Contains",
  StartWith = "StartsWith",
  EndsWith = "EndsWith",
  Equal = "Equal",
  Empty = "Empty",
  NotEmpty = "NotEmpty",
  DateRange = "DateRange",
  Selection = "Selection",
}
