import { Button, Checkbox, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import UserFeatureAccessWrapper from "components/UserFeatureAccessWrapper";
import { botsListSet, WorkerNameEnum } from "data/bots";
import { FeatureGroupSet } from "data/userPermissionsMap";
import AccountSimilarWinsCard from "./AccountSimilarWinsCard";
import { useSimilarWinsBot } from "./hooks";
import SimilarWinsForm from "./SimilarWinsForm";
import { ProgressionLoading } from "../shared";
import { AccountSimilarWins } from "types/api";
import { DownloadOutlined } from "@ant-design/icons";
import { CsvItem, downloadCsvFile, Formatter, jsonToCsvFormat } from "utils/file";
import generateRandomId from "utils/generateRandomId";
import { prepareListBuilderAccounts } from "./utils";
import { numberFormats } from "utils/numbers";



const formatters: Record<string, Formatter> = {
  numberOfEmployees: (numberOfEmployees: number) => `${numberOfEmployees} Employee`, // Custom formatting for score
  annualRevenue: (annualRevenue: number) => annualRevenue ? `${numberFormats.currency(annualRevenue)}` : '$0'
};


function ListBuilderWorker() {
  const listBuilderWorker = botsListSet.find((bot) => bot.key === WorkerNameEnum.LIST_BUILDER);
  const {
    getSimilarWins,
    onCancelRequest,
    result,
    errorMessage,
    resetErrorMessage,
    loading: isLoading,
    selectedAccounts,
    onUpdateSelected,
    isSavingAccounts,
    onSaveAccountsToTerritory,
    onResetResults
  } = useSimilarWinsBot();
  const onSubmit = (accounts: string[]) => {
    getSimilarWins({ accounts });
  };

  if (!listBuilderWorker) {
    return (
      <Box>
        Bot not found
      </Box>
    );
  }

  const onSelectAccounts = (parentName: string, selected: string) => {
    if (selected === 'all') {
      const allAccounts = result.find((account) => account.name === parentName)?.accounts.map((account) => account.name) || [];
      const allSelected = allAccounts.every((account) => selectedAccounts.includes(account));
      if (allSelected) {
        onUpdateSelected((selectedAccounts) => selectedAccounts.filter((account) => !allAccounts.includes(account)));
      } else {
        onUpdateSelected(allAccounts);
      }
    } else {
      const index = selectedAccounts.indexOf(selected);
      if (index > -1) {
        onUpdateSelected((selectedAccounts) => selectedAccounts.filter((account) => account !== selected));
      } else {
        onUpdateSelected((selectedAccounts) => [...selectedAccounts, selected]);
      }
    }
  }

  const resultsNumber = result.map((account) => account.accounts.length).reduce((acc, curr) => acc + curr, 0);
  const isAllResultSelected = result.every((account) => account.accounts.every((acc) => selectedAccounts.includes(acc.name)));
  const onSelectAllAccounts = () => {
    if (isAllResultSelected) {
      const allAccounts = result.map((account) => account.accounts.map((acc) => acc.name)).flat();
      onUpdateSelected((selectedAccounts) => selectedAccounts.filter((account) => !allAccounts.includes(account)));
    } else {
      const allAccounts = result.map((account) => account.accounts.map((acc) => acc.name)).flat();
      onUpdateSelected(allAccounts);
    }
  }

  const onDownloadSelected = () => {
    const columnNames = {
      parentAccount: 'Main account',
    };
    const priories = {
      parentAccount: 0
    }
    const preparedAccounts = prepareListBuilderAccounts(result)
    const csvLines = jsonToCsvFormat(preparedAccounts as CsvItem[], columnNames, formatters, priories)
    const fileName = `List Builder Worker-${generateRandomId()}.csv`
    downloadCsvFile(fileName, csvLines)
  };

  return (
    <Box sx={{
      p: "20px", display: "flex", flexDirection: "column", flexGrow: 1,
    }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          padding: "20px",
          backgroundColor: "#ffffff",
          borderRadius: "10px",
          border: "1px solid #e0e0e0",
          marginBottom: "20px",
        }}
      >
        <Box sx={{
          display: "flex", flexDirection: "row", gap: "20px", alignItems: "center",
        }}
        >
          <div style={{
            borderBottom: "1px solid #e0e0e0", paddingBottom: "10px", width: "70px", height: "70px", borderRadius: "50%", backgroundColor: "#f5f5f5", justifyContent: "center", alignItems: "center",
          }}
          >
            <img src={listBuilderWorker.image} alt="Similar wins Finder" style={{ width: "100%" }} />
          </div>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <Typography variant="h5">{listBuilderWorker.title}</Typography>
            <Typography variant="body1">{listBuilderWorker.description}</Typography>
          </Box>
        </Box>
        <SimilarWinsForm
          onFind={onSubmit}
          onResetErrorMessage={resetErrorMessage}
          isLoading={isLoading}
          onCancelRequest={onCancelRequest}
          onResetForm={onResetResults}
        />
      </Box>
      {isLoading ? (
        <ProgressionLoading searchEntityTitle="Similar accounts" />
      ) : (
        errorMessage ? (
          <Box sx={{
            padding: "20px", border: "1px solid #e0e0e0", borderRadius: "10px", marginBottom: "20px", backgroundColor: "#fff",
          }}
          >
            <Typography variant="body1">{errorMessage}</Typography>
          </Box>
        )
          : (
            <>
              {Boolean(result.length) && (
                <Box sx={{
                  padding: "20px",
                  border: "1px solid #e0e0e0",
                  borderRadius: "10px",
                  marginBottom: "20px",
                  backgroundColor: "#fff",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
                >
                  <Stack direction='row' gap='5px' alignItems={'center'} justifyContent={'center'}>
                    <Checkbox checked={isAllResultSelected} onChange={onSelectAllAccounts} />
                    <Typography variant="body1">
                      Found {resultsNumber} similar accounts, select accounts to save to territory.
                    </Typography>
                  </Stack>
                  <Stack direction='row' alignItems='center' gap='10px'>
                    <Button
                      disabled={!selectedAccounts.length || isLoading || isSavingAccounts}
                      onClick={onDownloadSelected}
                      variant='outlined'
                      sx={{
                        display: 'flex',
                        gap: '10px'
                      }}
                    >
                      <span>Download</span>
                      <DownloadOutlined style={{ fontSize: '17px' }} />
                    </Button>
                    <Button onClick={onSaveAccountsToTerritory} disabled={!selectedAccounts.length || isLoading || isSavingAccounts} variant="outlined" sx={{ gap: '5px' }}>
                      <span>Save to territory</span>
                      {isSavingAccounts && <CircularProgress size={12} color="inherit" />}
                    </Button>
                  </Stack>
                </Box>)}
              <Stack direction='column' gap='20px'>
                {result.map((account: AccountSimilarWins, index: number) => {
                  const subSimilarAccounts = account.accounts?.filter((acc) => account.name !== acc.name)
                  if (!(subSimilarAccounts.length)) return null
                  return (
                    <AccountSimilarWinsCard
                      key={`${account.name}_${index}`}
                      name={account.name}
                      accounts={subSimilarAccounts}
                      onSelectAccounts={onSelectAccounts}
                      selectedAccounts={selectedAccounts}
                    />
                  )
                })}
              </Stack>
            </>
          )
      )
      }
    </Box >
  );
}

function ListBuilderWorkerWrapper({ widget = WorkerNameEnum.LIST_BUILDER }: { widget?: WorkerNameEnum }) {
  return (
    <UserFeatureAccessWrapper groupName={FeatureGroupSet.GENERAL_ACCESS}>
      <ListBuilderWorker />
    </UserFeatureAccessWrapper>
  );
}
export default ListBuilderWorkerWrapper;
