import AddIcon from "@mui/icons-material/Add";
import ArchiveIcon from "@mui/icons-material/Archive";
import DeleteIcon from "@mui/icons-material/Delete";

import { Account } from "types/api";

import ConfirmModal from "../Filters/DynamicFilterComps/ConfirmModal";
import { PushToCrmStatusTooltip } from "./PushToCrmProgress";

type Props = {
  archiveAccounts: () => Promise<void>;
  deleteAccounts: () => Promise<void>;
  addAccountsToSF: () => Promise<void>;
  selectedAccounts: Record<string, boolean> | undefined;
  accounts: Account[] | undefined;
  isAllAccountsSelected?: boolean;
};

function DataActions({
  archiveAccounts,
  deleteAccounts,
  addAccountsToSF,
  selectedAccounts,
  isAllAccountsSelected,
  accounts,
}: Props) {
  const selectedAccsCount = selectedAccounts ? Object.values(selectedAccounts)
    .filter((value) => value).length : 0;

  const buttonSx = {
    color: (selectedAccsCount || isAllAccountsSelected) ? "#00223C" : "#757575",
    cursor: (selectedAccsCount || isAllAccountsSelected) ? "pointer" : "not-allowed",
    fontSize: "20px",
  };

  const selectedAccsInSFCount = (accounts ?? []).filter((account: Account) => !!selectedAccounts && selectedAccounts[account.id] && account.source.name === "Salesforce").length;

  const alreadyInSFErr = selectedAccsInSFCount === selectedAccsCount ? "All selected accounts are already in Salesforce" : "Selected account(s) already in Salesforce will not be overwritten";
  const selectSFAccsErr = selectedAccsInSFCount && !isAllAccountsSelected ? alreadyInSFErr : "";
  console.log('isAllAccountsSelected', isAllAccountsSelected);

  return (
    <>
      <ConfirmModal
        label="Archive"
        title="Archive Accounts"
        hoverTitle="Please select at least one account"
        allowHover={!selectedAccsCount}
        message="Are you sure you want to archive the selected account(s)?"
        onConfirm={archiveAccounts}
        icon={<ArchiveIcon />}
        labelSx={{ fontSize: "14px" }}
        buttonSx={buttonSx}
        dialogTitleSx={{ fontSize: "20px" }}
        dialogContentTextSx={{ fontSize: "16px" }}
        cancelButtonSx={{
          color: "black",
          "&:hover": {
            color: "#00223C",
            backgroundColor: "transparent",
          },
        }}
        confirmButtonSx={{
          backgroundColor: "#00223C",
          color: "white",
          "&:hover": {
            backgroundColor: "#757575",
          },
        }}
      />
      <ConfirmModal
        label="Delete"
        title="Delete Accounts"
        hoverTitle="Please select at least one account"
        allowHover={!selectedAccsCount}
        message="Are you sure you want to delete the selected account(s)?"
        onConfirm={deleteAccounts}
        icon={<DeleteIcon />}
        labelSx={{ fontSize: "14px" }}
        buttonSx={buttonSx}
        dialogTitleSx={{ fontSize: "20px" }}
        dialogContentTextSx={{ fontSize: "16px" }}
        cancelButtonSx={{
          color: "black",
          "&:hover": {
            color: "#00223C",
            backgroundColor: "transparent",
          },
        }}
        confirmButtonSx={{
          backgroundColor: "#00223C",
          color: "white",
          "&:hover": {
            backgroundColor: "#757575",
          },
        }}
      />
      <ConfirmModal
        label="Add to Salesforce"
        title="Add to Salesforce"
        hoverTitle="Please select an account not already in Salesforce"
        allowHover={false}
        message="Are you sure you want to add the selected account(s) to Salesforce?"
        onConfirm={addAccountsToSF}
        icon={<AddIcon />}
        error={selectSFAccsErr}
        disableSubmit={isAllAccountsSelected ? false : selectedAccsInSFCount === selectedAccsCount}
        labelSx={{ fontSize: "14px" }}
        buttonSx={buttonSx}
        dialogTitleSx={{ fontSize: "20px" }}
        dialogContentTextSx={{ fontSize: "16px" }}
        cancelButtonSx={{
          color: "black",
          "&:hover": {
            color: "#00223C",
            backgroundColor: "transparent",
          },
        }}
        confirmButtonSx={{
          backgroundColor: "#00223C",
          color: "white",
          "&:hover": {
            backgroundColor: "#757575",
          },
        }}
      />
      <PushToCrmStatusTooltip />
    </>
  );
}

export default DataActions;
