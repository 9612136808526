
import { UserOutlined } from "@ant-design/icons";
import { LinkOutlined } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { SalesforceIcon } from "assets/icons";
import { PipelineSignal } from "components/AccountTables";
import { signalLabels, signalReversed, signalStyles } from "components/AccountTables/PipelineSignal";
import CircularProgressBar from "components/common/AccountRankBadge";
import { Table } from "components/common/Tables";
import { HeaderCell } from "pages/Settings/UsersManagement/common";
import useUserSettings from "services/shooks/settings";
import { AccountSimilarWins, PowerRankAccount } from "types/api";
import { PipelineSignalEnums } from "types/api/territoryFilters";
import { getSalesForceLink } from "utils/generics";
import { numberFormats } from "utils/numbers";


interface AccountBusinessCardProps {
  accounts: PowerRankAccount[]
}

function PowerRankResultTable({ accounts }: AccountBusinessCardProps) {
  const { instanceURL } = useUserSettings(({ state }) => state);
  const columns = [
    {
      Header: () => (
        <HeaderCell width="100px">
          Account Name
        </HeaderCell>
      ),
      accessor: "accountName",
      style: { width: '150px' },
      Cell: ({ value: accountName, row }: { value: string, row: any }) => {
        const salesforceLink = getSalesForceLink({ instanceURL, objectId: row?.original?.accountId });
        return (
          <Box sx={{ color: "#262626", display: 'flex', alignItems: 'center', gap: '5px' }}>
            <Typography>{accountName}</Typography>
            {Boolean(row?.original?.accountId) && (
              <a href={salesforceLink} target="_blank" rel="noreferrer" style={{ display: 'flex', alignItems: 'center' }}>
                <SalesforceIcon />
              </a>
            )}
          </Box>
        );
      },
    },
    {
      Header: () => (
        <HeaderCell >
          Country
        </HeaderCell>
      ),
      accessor: "country",
      style: { width: '150px' },
      Cell: ({ value: country }: { value: string }) => {
        return (
          <Box sx={{ color: "#262626", display: 'flex', justifyContent: 'flex-start', py: '7px', textTransform: 'capitalize' }}>
            {country || 'N/A'}
          </Box>
        );
      }
    },
    {
      Header: () => (
        <HeaderCell >
          Annual Revenue
        </HeaderCell>
      ),
      accessor: "annualRevenue",
      style: { width: '200px' },
      Cell: ({ value: annualRevenue }: { value: number }) => {
        return (
          <Box sx={{ color: "#262626", py: '7px' }}>
            ${annualRevenue ? numberFormats.millify(annualRevenue) : 'N/A'}
          </Box>
        );
      }
    },
    {
      Header: () => (
        <HeaderCell width='150px' sx={{ textAlign: 'center' }}>
          Employees
        </HeaderCell>
      ),
      accessor: "size",
      style: { width: '150px' },
      Cell: ({ value: size }: { value: number }) => {
        return (
          <Box sx={{ maxWidth: '150px', color: "#262626", display: 'flex', gap: '5px', alignItems: 'center', justifyContent: 'flex-start', py: '7px' }}>
            <UserOutlined style={{ fontSize: '13px' }} />
            <span>
              {size ? numberFormats.millify(size) : 'N/A'}
            </span>
          </Box>
        );
      }
    }, {
      Header: () => (
        <HeaderCell >
          Industry
        </HeaderCell>
      ),
      accessor: "industry",
      style: { width: '250px' },
      Cell: ({ value: industry }: { value: string }) => {
        return (
          <Box sx={{ color: "#262626", py: '7px', textTransform: 'capitalize' }}>
            {industry || 'N/A'}
          </Box>
        );
      }
    },
    {
      Header: () => (
        <HeaderCell >
          Rank
        </HeaderCell>
      ),
      accessor: "rank",
      style: { width: '150px', textAlign: 'center' },
      Cell: ({ value: rank, original }: { value: string, original: any }) => {

        return (
          <Box sx={{ color: "#262626", display: 'flex', justifyContent: 'center', py: '7px' }}>
            <PipelineSignal signal={signalReversed[rank as 'Low']} />
          </Box>
        );
      }
    },
    {
      Header: () => (
        <HeaderCell width="100px">
          Rank Score
        </HeaderCell>
      ),
      accessor: "score",
      style: { width: '150px', textAlign: 'center' },
      Cell: ({ value: rankScore = 50, ...resetProps }: { value: number, row: any }) => {
        const signalRank = resetProps.row?.original?.rank
        const color = signalStyles[signalReversed[signalRank as keyof typeof signalReversed]]?.backgroundColor || '#3498db'
        return (
          <Box sx={{ color: "#262626", display: 'flex', justifyContent: 'center', py: '7px' }}>
            <CircularProgressBar progress={rankScore} size={43} strokeWidth={7} gradientColors={[color]} />
          </Box>
        );
      }
    }
  ]

  return (
    <Box sx={{
      display: "flex",
      flex: 1,
      flexDirection: "column",
      gap: "10px",
      padding: "20px",
      backgroundColor: "#ffffff",
      borderRadius: "10px",
      border: "1px solid #e0e0e0",
      width: "100%",
    }}
    >
      <Stack flex={1} direction="row" gap="10px" width={'100%'} sx={{ borderRadius: '10px', overflow: 'hidden' }}>
        <Table
          columns={columns as any}
          data={accounts as any}
          noDataMessage="No similar accounts found"
          stickyHeader={false}
          hasPagination={true}
          memoDeps={[new Date().getTime()]}
          cellSX={{
            padding: "15px 0",
            border: 'none',
          }}
        />
      </Stack>
    </Box>
  );
}

export default PowerRankResultTable;
