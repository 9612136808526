import { CaretDownFilled } from "@ant-design/icons";
import { CancelOutlined, LockOutlined, Search } from "@mui/icons-material";
import {
  Box, Button, Checkbox, Menu, MenuItem, Stack, TextField, Tooltip, Typography,
} from "@mui/material";
import {
  ChangeEvent, MouseEvent, useEffect, useMemo, useState,
} from "react";

import { ColumnIcon } from "assets/icons";
import { DynFilterColumn, TerritoryTableColumnsAccessors, TerritoryTableColumnsAccessorsOrder, TerritoryTableColumnTitles, TerritoryTableColumnWidths } from "types/api";
import { nonDisplayableCols } from "services/shooks/utils";
import { stubFalse } from "lodash";
import useAccessManager from "hooks/useAccessManager";
import { removeSFCustomSuffix } from "utils/generics/string";

export interface IHeaderDropdownProps {
  onUpdateColumnVisibility: (
    columns: DynFilterColumn
  ) => void;
  columnsMeta?: DynFilterColumn
}

const fixedColumnsIds = [TerritoryTableColumnsAccessors.account, TerritoryTableColumnsAccessors.selection]

export function HeaderDropdown({
  onUpdateColumnVisibility,
  columnsMeta = {}
}: IHeaderDropdownProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [searchValue, setSearchValue] = useState("");
  const [localColumns, setLocalColumns] = useState<DynFilterColumn>(columnsMeta)
  const { isFreeUser } = useAccessManager();
  const isForFreeUser = isFreeUser()

  const onResetColumnsSearch = () => {
    setSearchValue("");
  };

  const hasSearchValue = Boolean(searchValue?.length);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    onResetColumnsSearch();
  };

  const onColumnSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchValue(value)
  };

  const columnsBulkUpdate = (columns: DynFilterColumn, value: boolean) => {
    const newColumns: DynFilterColumn = {}
    for (let columnIndex in columns) {
      newColumns[columnIndex] = { ...columns[columnIndex], isViewed: value }
    }
    return newColumns
  }

  const onSelectNone = () => {
    const newColumns = columnsBulkUpdate(localColumns, false)
    setLocalColumns(newColumns)
  };

  const onSelectAll = (_event: MouseEvent<HTMLLIElement>) => {
    const newColumns = columnsBulkUpdate(localColumns, true)
    setLocalColumns(newColumns)
  };

  const onCheckboxChange = (event: MouseEvent<HTMLLIElement>, name: string) => {
    event.stopPropagation();
    const newColumns = {
      ...localColumns, [name]: { ...localColumns[name], isViewed: !localColumns[name]?.isViewed }
    };
    setLocalColumns(newColumns)
  };

  useEffect(() => {
    setTimeout(() => {
      onUpdateColumnVisibility(localColumns)
    }, 0)
  }, [localColumns]);

  useEffect(() => {
    setLocalColumns(columnsMeta)
  }, [JSON.stringify(columnsMeta)])

  const filteredColumnsList = useMemo(() => {
    return Object.keys(localColumns || {}).filter((key) => {
      const columnName = String(TerritoryTableColumnTitles[key as TerritoryTableColumnsAccessors]).toLowerCase()
      return !nonDisplayableCols.includes(key as any) && (searchValue ? columnName?.includes(searchValue.toLowerCase()) : true)
    }).map((key: any) => {
      const isLocked = isForFreeUser ? isForFreeUser !== localColumns[key].forFree : false;
      const isFixed = fixedColumnsIds.includes(key as TerritoryTableColumnsAccessors)
      return {
        label: TerritoryTableColumnTitles[key as TerritoryTableColumnsAccessors] || removeSFCustomSuffix(key),
        accessor: key,
        // @ts-ignore
        order: TerritoryTableColumnsAccessorsOrder[key as keyof typeof TerritoryTableColumnsAccessorsOrders] || 30,
        isViewed: (localColumns[key].isViewed && !isLocked) || isFixed,
        isLocked: isLocked && !isFixed,
        disabled: isFixed || isLocked
      }
    })
      .filter(Boolean)
      .sort((a, b) => a.order - b.order)

  }, [JSON.stringify(searchValue), JSON.stringify(localColumns), isForFreeUser])

  const visibleColumnsCount = filteredColumnsList
    .filter((column) => column.isViewed && !nonDisplayableCols
      .includes(column.accessor as any))
    .length

  return (
    <Box>
      <Button
        onClick={handleClick}
        variant="light"
        sx={{
          borderRadius: "4px",
          color: "#757575",
          display: "flex",
          alignItems: "center",
          gap: "8px",
        }}
      >
        <ColumnIcon style={{ fontSize: "22px", color: "#757575" }} />
        <Typography fontSize="14px" lineHeight="22px" fontWeight="500" sx={{ color: "#1A1A1A" }}>
          {visibleColumnsCount}
          {" "}
          Columns
        </Typography>
        <CaretDownFilled color="currentColor" style={{ fontSize: "14px", color: "#D9D9D9" }} />
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: 60, vertical: "top" }}
      >
        <Stack width="210px">
          <Stack p="12px">
            <TextField
              hiddenLabel
              onChange={onColumnSearchChange}
              size="small"
              placeholder="Column name"
              value={searchValue}
              InputProps={{
                style: { paddingLeft: 8, paddingRight: 8 },
                startAdornment: (
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Search style={{ fontSize: 15 }} />
                  </div>
                ),

                endAdornment: hasSearchValue && (
                  <div
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={onResetColumnsSearch}
                  >
                    <CancelOutlined style={{ fontSize: 15 }} />
                  </div>
                ),
              }}
            />
            <Stack direction="row" justifyContent="space-between">
              <Typography
                onClick={onSelectNone}
                variant="body2"
                sx={{
                  color: "#004E87",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "16px",
                  mt: "8px",
                  cursor: "pointer",
                }}
              >
                Select none
              </Typography>
              <Typography
                onClick={onSelectAll}
                variant="body2"
                sx={{
                  color: "#004E87",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "16px",
                  mt: "8px",
                  cursor: "pointer",
                }}
              >
                Select all
              </Typography>
            </Stack>
          </Stack>

          {filteredColumnsList.map((column, index) => (
            <MenuItem
              key={`${column.label}${index}${column.isViewed}`}
              sx={{
                borderTop: "1px solid #ebeced",
                height: "30px",
                padding: "0 10px 0 2px",
                overflowY: "hidden",
              }}
              onClick={(e) => !column.isLocked && onCheckboxChange(e, column.accessor)}
              disabled={column.disabled}
            >
              <Checkbox checked={column.isViewed} />
              <Typography
                className="truncate"
                variant="body2"
                sx={{ fontSize: "12px", fontWeight: 500 }}
              >
                {column.label}
              </Typography>
              {column.isLocked && (
                <Tooltip placement="bottom" title='Upgrade to unlock'>
                  <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
                    <LockOutlined sx={{ ml: '5px', color: '#1890ff', fontSize: '15px' }} />
                  </Box>
                </Tooltip>
              )}
            </MenuItem>
          ))}
        </Stack>
      </Menu>
    </Box>
  );
}
