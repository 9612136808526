import { useAuth0 } from "@auth0/auth0-react";
import {
  Box, FormControl, OutlinedInput, Select, Skeleton, Typography,
} from "@mui/material";
import UserFeatureAccessWrapper from "components/UserFeatureAccessWrapper";
import { FeatureGroupSet } from "data/userPermissionsMap";

import { useEffect, useMemo, useState } from "react";
import { useEmployeeTree, useSettings } from "services/shooks";

import useEmployeeHierarchy from "services/shooks/EmployeeHierarchy";
import useTerritoryAccounts from "services/shooks/territory";
import type { IExtendedEmployee } from "types/api";

import { EmployeeHierarchy as EmployeeHierarchyCore } from "./components";
import { UpdatedEmployee } from "./types";
import {
  findMatchesInTree,
  updateAllEmployeeStatus,
  updateEmployeeInTree,
  updateTreeScope,
} from "./utils";
import { getScopeString } from "./utils/getScopeString";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function EmployeeHierarchy() {
  const {
    state,
    isLoading,
    isInitialized,
    actions: employeeHierarchyActions,
  } = useEmployeeHierarchy();
  const {
    actions: territoryActions,
    state: { selectedUserIds },
  } = useTerritoryAccounts();
  const { hierarchy } = state;
  const { user } = useAuth0<User>();
  const [employeeHierarchy, setEmployeeHierarchy] = useState<IExtendedEmployee[]>([]);
  const [scope, setScope] = useState<UpdatedEmployee[]>([]);
  const selectWidth = hierarchy.length ? "320px" : "auto";
  const managerCount = Object.values(scope).filter(
    (item: any) => item?.subordinates?.length > 0,
  ).length;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const employeeCount = useMemo(
    () => selectedUserIds?.filter((employeeId: string) => !employeeId.endsWith("parent")).length,
    [JSON.stringify(selectedUserIds)],
  );
  // @hotfix: this is a temporary fix to get the root subordinates. We should rework the endpoint to reflect the requirement (change hierarchy structure).
  const rootSubordinates = hierarchy?.[0]?.subordinates;

  const scopeString = getScopeString(employeeHierarchy?.[0]?.subordinates);

  // @todo: move to hooks file.
  useEffect(() => {
    const updatedScope = updateTreeScope(hierarchy[0]?.subordinates, scope);
    setScope(updatedScope);
    // update selected users
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeHierarchy, JSON.stringify(scope), employeeCount]);

  // @todo: this should be a util function
  const updateEmployeeCheckStatus = (employee: IExtendedEmployee, checkState?: boolean) => {
    const newEmployeeList = updateEmployeeInTree(
      employee.id,
      (currentEmployee: IExtendedEmployee) => {
        currentEmployee.checked = checkState ?? !employee.checked;
        if (currentEmployee.subordinates.length) {
          const updatedSubordinates = currentEmployee.subordinates.map(
            (subordinate: IExtendedEmployee) => {
              updateEmployeeCheckStatus(subordinate, currentEmployee.checked);
              return subordinate;
            },
          );
          currentEmployee.subordinates = updatedSubordinates;
        }
        return currentEmployee;
      },
      employeeHierarchy,
    );

    return newEmployeeList;
  };

  const onSelectEmployee = (employee: IExtendedEmployee, checkStatus?: boolean) => {
    const newEmployeeList = updateEmployeeCheckStatus(employee, checkStatus);
    setEmployeeHierarchy(newEmployeeList);
    employeeHierarchyActions.updateEmployeeHierarchy(newEmployeeList);
  };

  useEffect(() => {
    const hasValidHierarchy = Boolean(hierarchy?.length);
    if (!hasValidHierarchy) {
      const defaultCheckedHierarchy = updateAllEmployeeStatus(employeeHierarchy, true);
      setEmployeeHierarchy(defaultCheckedHierarchy);
      employeeHierarchyActions.updateEmployeeHierarchy(defaultCheckedHierarchy);
    }

    setEmployeeHierarchy(hierarchy);
    // update selected users
    // @deprecated
    // const selectedEmployees = findMatchesInTree(
    //   hierarchy,
    //   (employee: IExtendedEmployee) => employee.checked,
    // );
    // const selectedEmployeeIds = selectedEmployees.map((employee: IExtendedEmployee) => employee.id);
    // const uniqueSelectedEmployeeIds = [...new Set(selectedEmployeeIds)];
    // territoryActions.updateSelectedUserIds(uniqueSelectedEmployeeIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(hierarchy)]);

  if (!isInitialized) return <Skeleton variant="rectangular" width={320} height={41} />;
  return (
    <Box sx={{ width: selectWidth }}>
      {rootSubordinates.length === 0 && !isLoading ? (
        <Typography variant="body1" sx={{ fontSize: "27px", fontWeight: 400 }}>
          {user?.given_name}
          's Territory
        </Typography>
      ) : (
        <FormControl fullWidth>
          <Select
            id="hierarchy-multiple-checkbox"
            multiple
            value={[]}
            input={<OutlinedInput />}
            placeholder="Hierarchy"
            renderValue={(selected) => (
              <Box>
                <Box>
                  <Typography
                    variant="body1"
                    component="span"
                    sx={{ fontSize: "14px", fontWeight: 700 }}
                  >
                    {scopeString}
                  </Typography>
                </Box>
              </Box>
            )}
            displayEmpty
            MenuProps={MenuProps}
            sx={{
              "& .MuiBox-root": { padding: "0 !important" },
            }}
          >
            {/* todo: check for hierarchy and provide skeleton while loading. */}
            {rootSubordinates
              .filter((employee) => employee.name)
              .map((employee) => (
                <EmployeeHierarchyCore
                  key={employee.id}
                  employee={employee as IExtendedEmployee}
                  onSelectEmployee={onSelectEmployee}
                />
              ))}
          </Select>
        </FormControl>
      )}
    </Box>
  );
}

function EmployeeHierarchWrapper(props: any) {
  const hasSubordinatesToView = useSettings((state) => Boolean(state.state?.employeeHierarchy?.subordinate?.length));
  const { actions: employeeTreeActions } = useEmployeeTree();
  const { user } = useAuth0<User>();

  useEffect(() => {
    if (!user?.SFUserId && hasSubordinatesToView) return;
    employeeTreeActions.initialize({ userId: user?.SFUserId as string });
  }, [hasSubordinatesToView]);

  return (
    <UserFeatureAccessWrapper groupName={FeatureGroupSet.EMPLOYEE_HIERARCHY}>
      <EmployeeHierarchy />
    </UserFeatureAccessWrapper>
  )
}
export default EmployeeHierarchWrapper;
