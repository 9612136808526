import { enqueueSnackbar } from "notistack";

import { PagePayload } from "services/territory";
import {
  DynamicFilterT,
} from "types/api";
import { AxiosNode } from "utils/axios";

export type TableViewT = {
  name: string;
  id: string;
  filters: DynamicFilterT;
};

const Views = {
  createView: async (
    filters: DynamicFilterT,
    name: string,
  ): Promise<{
    success: boolean;
    response: TableViewT[],
    error?: any;
  }> => {
    const { limit: _limit, offset: _offset, ...filtersPayload } = filters;
    let response;
    try {
      response = await AxiosNode.post<TableViewT[]>(
        "/view/create",
        {
          filters: filtersPayload,
          name,
        },
      );
      return { success: true, response: response.data };
    } catch (error) {
      return { success: false, error, response: [] };
    }
  },
  getViews: async (): Promise<TableViewT[]> => {
    try {
      const response = await AxiosNode.get<TableViewT[]>("/views");
      return response.data;
    } catch (err) {
      return [];
    }
  },
  deleteView: async (id: string): Promise<{
    success: boolean;
    response: any;
  }> => {
    try {
      const response = await AxiosNode.post("/views/delete", { ids: [id] });
      return { success: true, response: response.data };
    } catch (err) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
      return { success: false, response: err };
    }
  },
  getView: async (id: string): Promise<TableViewT> => {
    try {
      const tableId = id ? `?tableId=${id}` : ''
      const response = await AxiosNode.get<TableViewT>(`/views/filter${tableId}`);
      return response.data;
    } catch (err) {
      return {} as TableViewT;
    }
  },
  updateView: async (
    filters: DynamicFilterT,
    id: string,
  ): Promise<{
    success: boolean;
    response: TableViewT[],
    error?: any;
  }> => {
    const { limit: _limit, offset: _offset, ...filtersPayload } = filters;
    let response;
    try {
      response = await AxiosNode.post<any>(
        "/view/update",
        {
          payload: {
            filters: filtersPayload,
          },
          id,
        },
      );
      return { success: true, response: response.data };
    } catch (error) {
      return { success: false, error, response: [] };
    }
  }
};

export default Views;
