import { useSearchQuery } from "hooks";
import { groupBy } from "lodash";
import {
  useCallback, useEffect, useRef, useState,
} from "react";
import { toast } from "react-toastify";

import aiWorkersService, { PersonResult } from "services/aiworkers";

export const groupPeopleByAccount = (selected: string[], people: PersonResult[]) => {
  const selectedPersonId = selected
    .map((selectedPersonId: string) => people.find((person) => person.id === selectedPersonId))
    .filter((person) => !person?.isAlreadySaved);

  const results = groupBy(selectedPersonId, 'accountId');
  return Object.entries(results).map(([accountId, people]) => ({ accountId, peopleIds: people.map(({ id: personId } = {} as PersonResult) => personId) }))
};

export enum ContactTypeEnum {
  Lead = 'Lead',
  Contact = 'Contact'
}
export const usePersonRecommender = ({ focusedAccount }: any = {}) => {
  const [result, setResult] = useState<PersonResult[]>([] as PersonResult[]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const abortSignal = useRef(new AbortController());
  const [selectedPeople, setSelectedPeople] = useState<string[]>([]);
  const [isSavingPeople, setIsSavingPeople] = useState<boolean>(false);
  const [contactType, setContactType] = useState<ContactTypeEnum>(ContactTypeEnum.Lead);
  const onContactSavedAsChange = (type: ContactTypeEnum) => {
    setContactType(type)
  }

  const find = async ({ accounts, jobs }: { accounts: string[], jobs: string[] }) => {
    try {
      abortSignal.current.abort();
      setLoading(true);
      setErrorMessage(null);
      abortSignal.current = new AbortController();
      const { results: peopleResults } = await aiWorkersService.peopleRecommender({
        accounts: accounts
      }, {
        signal: abortSignal.current.signal
      });
      if (!peopleResults.peopleFound?.length) {
        return setErrorMessage("No results found");
      }
      setResult(peopleResults.peopleFound);
    } catch (e: any) {
      if (!abortSignal.current.signal.aborted)
        setErrorMessage(e.response?.data?.message || "An error occurred while fetching data");
    } finally {
      setLoading(false);
    }
  };

  const resetErrorMessage = () => {
    setErrorMessage(null);
  };

  const onCancelRequest = () => {
    abortSignal.current.abort();
    setLoading(false);
    setErrorMessage(null);
  }

  const onResetResults = () => {
    setResult([]);
    setErrorMessage(null);
  }

  const onSavePeopleToAccounts = async () => {
    try {
      setIsSavingPeople(true);
      setErrorMessage(null);
      const payloads = groupPeopleByAccount(selectedPeople, result);
      await aiWorkersService.savePeopleToAccounts({ data: payloads, contactType })
      toast.success("Accounts saved to territory successfully");
      updateSavedAccountStatus(payloads)
      setIsSavingPeople(false);
      setSelectedPeople([]);
    } catch (e: any) {
      setErrorMessage(e.response?.data?.message || "An error occurred while saving data");
      setIsSavingPeople(false);
    }
  }
  const updateSavedAccountStatus = (payload: ReturnType<typeof groupPeopleByAccount>) => {
    const savedAccountsIds = payload.map((accountPeople) => accountPeople.peopleIds).flat();
    const updatedResults = result.map((person) => savedAccountsIds.includes(person.id) ? { ...person, isAlreadySaved: true } : person)
    setResult(updatedResults);
  }

  return {
    result,
    loading,
    find,
    errorMessage,
    resetErrorMessage,
    onCancelRequest,
    onResetResults,
    selectedPeople,
    onUpdateSelected: setSelectedPeople,
    onSavePeopleToAccounts,
    isSavingPeople,
    contactType,
    onContactSavedAsChange,
  };
};

export const useAccountSearch = () => {
  const [accounts, setAccounts] = useState<{ name: string, domain: string }[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [searchKey, setSearchKey] = useState<string>("");
  const abortSignal = useRef(new AbortController());
  const search = useCallback(async (query?: string) => {
    try {
      abortSignal.current.abort();
      if (!query) {
        return setAccounts([]);
      }
      setLoading(true);
      setErrorMessage(null);
      abortSignal.current = new AbortController();
      const foundAccounts = await aiWorkersService.searchAccounts(query, { signal: abortSignal?.current.signal });
      if (!foundAccounts.length) {
        setAccounts([]);
        setErrorMessage("No accounts found");
        return
      }
      const firstTenAccounts = foundAccounts.slice(0, 10);
      setAccounts(firstTenAccounts);
    } catch (e: any) {
      setErrorMessage(e.response?.data?.message || "An error occurred while fetching data");
    } finally {
      setLoading(false);
    }
  }, [searchKey]);

  const onSearchKeyUpdates = (key: string) => {
    setSearchKey(key);
  };

  useEffect(() => {
    search(searchKey);
  }, [searchKey]);

  return {
    accounts,
    loading,
    searchKey,
    onSearchKeyUpdates,
    errorMessage,
  };
};
