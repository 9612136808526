import React from 'react';
import { Box, Typography, LinearProgress, Alert, Tooltip, Stack, Button } from '@mui/material';
import { CheckCircleOutline, RefreshOutlined, WarningAmberOutlined } from '@mui/icons-material';
import useTerritoryFilters, { JobStatus } from 'services/shooks/territoryFilters';
import { AlertOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Switcher } from 'components/common';
import { CloseIcon } from 'assets/icons';


export interface PushToCrmProgressState {
  progress: {
    total: number;
    complete: number;
    failed?: number;
  };
  status: JobStatus;
  errorMessage?: string;
}


const PushToCrmProgress: React.FC<PushToCrmProgressState> = ({ progress, status, errorMessage }) => {
  const percentage = (progress.complete / progress.total) * 100;

  return (
    <Box sx={{ minWidth: '250px', maxWidth: '420px', borderRadius: 2, backgroundColor: 'white', border: '1px solid #E0E0E0', overflow: 'hidden' }}>
      {status === JobStatus.InProgress && (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '5px', m: 2 }} mt={2} mb={1}>
          <LinearProgress variant="determinate" value={percentage} sx={{ width: '100%' }} />
          <Typography variant="body2" color="textSecondary" align="center" >
            All accounts: {progress.total} pushed ({Math.round(percentage)}%)
          </Typography>
        </Box>
      )}
      {status === JobStatus.Complete && (
        <Alert severity="success" sx={{ mt: 0, fontSize: '12px', lineHeight: '22px' }}>
          All accounts pushed successfully
        </Alert>
      )}
      {status === JobStatus.Failed && (
        <Alert severity="error" sx={{ mt: 0, fontSize: '12px', lineHeight: '22px' }}>
          {errorMessage || 'Failed to push accounts to CRM'}
        </Alert>
      )}
      {status === JobStatus.CompleteWithError && (
        <Alert severity="warning" sx={{ mt: 0, fontSize: '12px', lineHeight: '22px' }}>
          {progress.complete} accounts pushed successfully, {progress.failed} accounts failed to push.
        </Alert>
      )}
    </Box>
  );
};

export default PushToCrmProgress;


const InProgressStatusLabel = () => {
  return (
    <Typography variant="subtitle1" sx={{ color: '#1890ff', display: 'flex', alignItems: 'center', gap: '5px' }} color={'textPrimary'}>
      <RefreshOutlined className="rotate-infinite" sx={{ fontSize: '16px' }} />
      <span>In Progress...</span>
    </Typography>
  )
}

interface CompleteStatusLabelProps {
  onClose: () => void;
}

const CompleteStatusLabel = (props: CompleteStatusLabelProps) => {
  const { onClose } = props;
  return (
    <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center', gap: '5px' }} color={'success.main'}>
      <CheckCircleOutline sx={{ fontSize: '17px', marginBottom: '3px' }} />
      <span>Complete</span>
      <Tooltip title="Close" arrow>
        <CloseCircleOutlined onClick={onClose} style={{ fontSize: '18px', marginLeft: '20px', cursor: 'pointer', color: '#000' }} />
      </Tooltip>
    </Typography>
  )
}

interface FailedStatusLabelProps {
  onClose: () => void;
  onRetry: () => Promise<any>;
}

const FailedStatusLabel = (props: FailedStatusLabelProps) => {
  const { onClose, onRetry } = props;
  return (
    <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center', gap: '10px' }} color={'error'}>
      <AlertOutlined style={{ fontSize: '16px', marginBottom: '3px' }} />
      <span>Failed</span>
      <Tooltip title="Retry the failing accounts" arrow>
        <Button onClick={onRetry} variant="text" color="primary" sx={{ fontSize: '12px', textTransform: 'none' }}>Retry</Button>
      </Tooltip>
      <Tooltip title="Close" arrow>
        <CloseCircleOutlined onClick={onClose} style={{ fontSize: '18px', marginLeft: '20px', cursor: 'pointer', color: '#000' }} />
      </Tooltip>
    </Typography>
  )
}

interface CompleteWithErrorStatusLabelProps {
  onClose: () => void;
  onRetry: () => Promise<any>;
}

const CompleteWithErrorStatusLabel = (props: CompleteWithErrorStatusLabelProps) => {
  const { onClose, onRetry } = props;
  return (
    <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center', gap: '5px' }} color={'orange'}>
      <WarningAmberOutlined sx={{ fontSize: '18px', marginBottom: '3px' }} />
      <span>Pushing accounts completed with errors</span>
      <Tooltip title="Retry the failing accounts" arrow>
        <Button onClick={onRetry} variant="text" color="primary" sx={{ fontSize: '12px', textTransform: 'none' }}>Retry</Button>
      </Tooltip>
      <Tooltip title="Close" arrow>
        <CloseCircleOutlined onClick={onClose} style={{ fontSize: '18px', marginLeft: '20px', cursor: 'pointer', color: '#000' }} />
      </Tooltip>
    </Typography>
  )
}

interface StatusDisplayProps {
  status: JobStatus;
  onRetry: () => Promise<any>;
  onClose: () => void;
}
const StatusDisplay = (props: StatusDisplayProps) => {
  const { status, onRetry, onClose } = props;
  return (
    <Switcher active={status}>
      <Switcher.Case id={JobStatus.InProgress}>
        <InProgressStatusLabel />
      </Switcher.Case>
      <Switcher.Case id={JobStatus.Complete}>
        <CompleteStatusLabel onClose={onClose} />
      </Switcher.Case>
      <Switcher.Case id={JobStatus.Failed}>
        <FailedStatusLabel onClose={onClose} onRetry={onRetry} />
      </Switcher.Case>
      <Switcher.Case id={JobStatus.CompleteWithError}>
        <CompleteWithErrorStatusLabel onClose={onClose} onRetry={onRetry} />
      </Switcher.Case>
    </Switcher>
  )
}


export const PushToCrmStatusTooltip = () => {
  const [{ progress, status, errorMessage, isActive }, actions] = useTerritoryFilters((state) => [(state.state?.pushToCrmProgress || {}), state.actions]);
  if (!isActive) return null;

  const onHideWidget = () => {
    actions.updatePushToCrmProgress({
      isActive: false
    })
  }

  const onRetry = async () => {
    return actions.addAccountsToSF();
  }

  return (
    <Tooltip
      title={(
        <PushToCrmProgress
          progress={progress}
          status={status}
          errorMessage={errorMessage}
        />
      )}
      // arrow
      PopperProps={{
        sx: {
          marginTop: '0px',
          '&[data-popper-placement*="bottom"] .MuiTooltip-tooltip': {
            // Apply your styles here
            padding: '0px',
            marginTop: '0px',
            backgroundColor: 'transparent', // Change background color if needed
            borderRadius: '10px',
          },
          '& .MuiTooltip-tooltip': {
            backgroundColor: 'transparent', // Change the background color here
            borderRadius: '10px'
          },
          '& .MuiTooltip-arrow': {
            color: 'transparent', // Change arrow color to match the background
          },
        },
      }}
    >
      <Stack
        direction='row'
        justifyContent='center'
        alignItems='center'
        gap='10px'
      >
        <StatusDisplay status={status} onClose={onHideWidget} onRetry={onRetry} />
      </Stack>
    </Tooltip>
  )
}
