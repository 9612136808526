import { AccountSimilarWins, CompanySecData, PowerRankAccount, PowerRankWorkerResponse, SimilarWinsStatistics, WorkerPreCachedParams } from "types/api";
import { AxiosNode, fetchWithAuth } from "utils/axios";

export interface PersonResult {
  id: string;
  name: string;
  email: string;
  title: string;
  normalizedTitle: string;
  linkedInProfile: string;
  photo: string;
  accountName: string;
  accountId: string;
  isAlreadySaved: boolean;
  isInUserScope?: boolean
}

export type IPersonFromFinderBot = {
  results: PersonResult[];
  invalid: string[];
};

export type IAccount2People = {
  results: {
    peopleFound: PersonResult[];
    note: string;
    input_titles: string[];
  }
  invalid: string[];
};
export interface IAccountBusiness {
  name: string;
  description: string;
  website: string;
  industry: string;
  annualRevenue: number;
  numberOfEmployees: number;
}

export type IPeopleContactMetaData = {
  fields: { name: string; label: string; type: string }[];
};

export type PaginatedPayload = {
  accounts?: string[];
  jobs?: string[]
  isIds?: boolean
};

export type Account2PeoplePayload = {
  accounts: string[]
};

export type PaginatedSavePeoplePayload = {
  data: {
    accountId: string;
    peopleIds: string[]
  }[],
  contactType?: string
}

export interface SimilarWinsWorkerResponse {
  results: AccountSimilarWins[]
  statistics: SimilarWinsStatistics
}

interface CompanySecDataResponse {
  data: CompanySecData;
}

interface WorkerPreCachedParamsPayload {
  data: WorkerPreCachedParams;
}
const aiWorkersService = {
  findPeople: async (
    payload: PaginatedPayload,
    options?: Parameters<typeof AxiosNode.post>[2],
  ) => {
    const { data } = await AxiosNode.post<IPersonFromFinderBot>(
      "/bot/personfinder",
      payload,
      options,
    );
    return data
  },
  peopleRecommender: async (
    payload: Account2PeoplePayload,
    options?: Parameters<typeof AxiosNode.post>[2],
  ) => {
    const accessToken = localStorage.getItem('accessToken')!;
    const response = await fetchWithAuth('https://node-staging-function-app.azurewebsites.net/api/bot/personRecommender', accessToken, payload)
    // const { data } = await AxiosNode.post<IAccount2People>(
    //   "/bot/personRecommender",
    //   payload,
    //   options,
    // );
    return response as IAccount2People
  },
  findSimilarWins: async (
    payload: PaginatedPayload,
    options?: Parameters<typeof AxiosNode.post>[2],
  ) => {
    const { data } = await AxiosNode.post<SimilarWinsWorkerResponse>(
      "/bot/similarwins",
      payload,
      options,
    );
    return data as SimilarWinsWorkerResponse
  },
  searchAccounts: async (query: string, options?: Parameters<typeof AxiosNode.post>[2]) => {
    const { data } = await AxiosNode.get<{ name: string, domain: string }[]>(`/accounts/search?search=${query}`, options);
    return data;
  },
  powerRank: async (
    payload: PaginatedPayload,
    options?: Parameters<typeof AxiosNode.post>[2],
  ) => {
    const { data } = await AxiosNode.post<PowerRankWorkerResponse>(
      "/bot/accountRank",
      payload,
      options,
    );
    return data
  },
  saveAccountsToTerritory: async (
    payload: PaginatedPayload,
    options?: Parameters<typeof AxiosNode.post>[2],
  ) => {
    const { data } = await AxiosNode.post<PowerRankAccount[]>(
      "/user/territory/add",
      payload,
      options,
    );
    return data;
  },
  getCompanySecFilings: async (ticker: string, options?: Parameters<typeof AxiosNode.get>[1]) => {
    const { data } = await AxiosNode.get<CompanySecDataResponse>(`/company/filing?ticker=${ticker}`, options);
    return data;
  },
  updateCompanySecFilings: async (ticker: string, options?: Parameters<typeof AxiosNode.get>[1]) => {
    const { data } = await AxiosNode.post<CompanySecDataResponse>(`/company/filing?ticker=${ticker}`, options);
    return data;
  },
  savePeopleToAccounts: async (
    payload: PaginatedSavePeoplePayload,
    options?: Parameters<typeof AxiosNode.post>[2],
  ) => {
    const { data } = await AxiosNode.post<any>(
      "/people/create",
      payload,
      options,
    );
    return data;
  },
  workerCachedParams: async (id: string, options?: Parameters<typeof AxiosNode.get>[1]) => {
    const { data } = await AxiosNode.get<WorkerPreCachedParamsPayload>(`/bots/result?id=${id}`, options);
    return data;
  },
  savePeopleToCRM: async (
    payload: PaginatedSavePeoplePayload,
    options?: Parameters<typeof AxiosNode.post>[2],
  ) => {
    try {
      const { data } = await AxiosNode.post<any>(
        "/crm/people",
        payload,
        options,
      );
      return data;
    } catch (error: any) {
      return { error: error.message }
    }
  }
};

export default aiWorkersService;

