import envConfig from "config/envConfig";
import { TerritoryFiltersState } from "services/shooks/territoryFilters";
import { IAccount } from "types/territory";

const prepareRowData = (
  accountData: IAccount,
  selectedTableRows?: TerritoryFiltersState["selectionState"],
) => {
  // TODO: this should be done on the server?
  // next line: first result doesn't have closedOpportunities key for some reason on dev. This results a NaN value without the || 0 part
  const closedOpportunities: number = accountData.closedOpportunitiesAmount || 0;
  const totalCovered: number = closedOpportunities + accountData.openOpportunitiesAmount;
  const totalTAM: number = accountData.TAMamount > totalCovered ? accountData.TAMamount : totalCovered;

  const uncapturedValue = (accountData.TAMamount || totalTAM)
    - (accountData.openOpportunitiesAmount + accountData.closedOpportunitiesAmount);

  // this is not a dummy data.
  const isAllSelected = selectedTableRows?.isSelectAll;
  const selectionRowValue = selectedTableRows?.selected?.[accountData.id];

  //  if the "isAllSelected" has value true, the "selected" object will behave as excluded items instead of included items
  const selected = isAllSelected ? !selectionRowValue : Boolean(selectionRowValue);

  const whitespace = envConfig.apiVersion == "V1" ? (uncapturedValue > 0 ? uncapturedValue : 0) : accountData.uncapturedWhitespaceAmount;
  const uncaptured = envConfig.apiVersion == "V1" ? (uncapturedValue > 0 ? uncapturedValue : 0) : accountData.uncapturedWhitespaceAmount;

  return {
    ...accountData,
    id: accountData.id,
    name: accountData.name,
    lastSalesActivity: accountData.lastSalesActivity,
    lastCustomerSignal: accountData.lastCustomerSignal,
    TAMamount: totalTAM,
    // @todo: remove mock data when the endpoint is finished.
    accountStatus: accountData.accountStatus || {},
    annualRecurringRevenue: accountData,
    annualRevenue: accountData.annualRevenue,
    employeeCount: accountData.employeeCount,
    industry: accountData.industry,
    intentSignal: accountData.intentSignal,
    marketingSignal: accountData.marketingSignal,
    nextRenewalDate: accountData.nextRenewalDate,
    openOpportunitiesAmount: accountData.openOpportunitiesAmount,
    closedOpporunitiesAmount: accountData.closedOpportunitiesAmount,
    ownerUid: accountData.ownerUid,
    pipelineSignal: accountData.pipelineSignal, // mapAccountFitRank(accountData.pipelineSignal as unknown as AccountFitRank),
    products: accountData.products || [],
    priority: accountData.priority,
    rank: accountData.rank,
    salesforceLink: accountData.salesforceLink,
    similarWins: accountData.similarWins,
    status: accountData.status || "",
    targetAccount: accountData.targetAccount,
    ownerName: accountData.ownerName,
    walletShare: {
      open: accountData.openOpportunitiesAmount,
      closed: accountData.closedOpportunitiesAmount,
      // @todo: does this need to be changes @farah
      uncaptured,
    },
    whitespace,
    selected,
    sdrPersonName: accountData.sdrPersonName,
    OBLastActivitySDR: accountData.OBLastActivitySDR,
    AELastActivity: accountData.AELastActivity,
    RecordType: accountData.RecordType,
    emailProvider: accountData.emailProvider,
    CRM: accountData.CRM,
  };
};

// TODO: did a minor refactors for this sprint. Do a full refactor later.
export const makeTerritoryData = (
  territoryTableAccounts: any,
  selectedTableRows?: TerritoryFiltersState["selectionState"],
) => {
  if (!Array.isArray(territoryTableAccounts)) return [];
  return territoryTableAccounts.map((account: IAccount) => ({
    ...prepareRowData(account, selectedTableRows),
    subRows: undefined,
  }));
};


export const defaultTerritoryColumnsView = {
  "name": {
    "editable": false,
    "type": "String",
    "sortable": false,
    "forFree": true,
    "isViewed": true,
    "viewForFree": true
  },
  "ownerName": {
    "editable": false,
    "type": "String",
    "sortable": false,
    "forFree": false,
    "isViewed": true,
    "viewForFree": false
  },
  "pipelineSignal": {
    "editable": false,
    "type": "String",
    "sortable": true,
    "forFree": false,
    "isViewed": true,
    "viewForFree": false
  },
  "industry": {
    "editable": false,
    "type": "String",
    "sortable": true,
    "forFree": true,
    "isViewed": true,
    "viewForFree": true
  },
  "employees": {
    "editable": true,
    "type": "Number",
    "sortable": true,
    "forFree": true,
    "isViewed": true,
    "viewForFree": true
  },
  "annualRevenue": {
    "editable": true,
    "type": "Number",
    "sortable": false,
    "forFree": true,
    "isViewed": true,
    "viewForFree": true
  },
  "location": {
    "editable": false,
    "type": "String",
    "sortable": false,
    "forFree": true,
    "isViewed": true,
    "viewForFree": true
  },
  "state": {
    "editable": false,
    "type": "String",
    "sortable": false,
    "forFree": true,
    "isViewed": true,
    "viewForFree": true
  },
  "city": {
    "editable": false,
    "type": "String",
    "sortable": false,
    "forFree": true,
    "isViewed": true,
    "viewForFree": true
  },
  "technologies": {
    "editable": false,
    "type": "String",
    "sortable": false,
    "forFree": true,
    "isViewed": true,
    "viewForFree": true
  },
  "similarWins": {
    "editable": false,
    "type": "String",
    "sortable": true,
    "forFree": false,
    "isViewed": true,
    "viewForFree": false
  },
  "priority": {
    "editable": false,
    "type": "Object",
    "sortable": false,
    "forFree": false,
    "isViewed": true,
    "viewForFree": false
  },
  "lastCustomerSignal": {
    "editable": false,
    "type": "Object",
    "sortable": false,
    "forFree": false,
    "isViewed": true,
    "viewForFree": false
  },
  "nextRenewalDate": {
    "editable": false,
    "type": "Object",
    "sortable": false,
    "forFree": false,
    "isViewed": true,
    "viewForFree": false
  },
  "openOpportunitiesAmount": {
    "editable": false,
    "type": "Number",
    "sortable": false,
    "forFree": false,
    "isViewed": true,
    "viewForFree": false
  },
  "closedOpportunitiesAmount": {
    "editable": false,
    "type": "Number",
    "sortable": false,
    "forFree": false,
    "isViewed": true,
    "viewForFree": false
  },
  "totalClosedOpportunities": {
    "editable": false,
    "type": "Number",
    "sortable": false,
    "forFree": false,
    "isViewed": true,
    "viewForFree": false
  },
  "totalOpenOpportunities": {
    "editable": false,
    "type": "Number",
    "sortable": false,
    "forFree": false,
    "isViewed": true,
    "viewForFree": false
  },
  "totalLosesToDate": {
    "editable": false,
    "type": "Number",
    "sortable": false,
    "forFree": false,
    "isViewed": true,
    "viewForFree": false
  },
  "totalWinsToDate": {
    "editable": false,
    "type": "Number",
    "sortable": false,
    "forFree": false,
    "isViewed": true,
    "viewForFree": false
  },
  "id": {
    "isViewed": true,
    "editable": false,
    "label": "Selection",
    "sortable": false
  }
}

export const defaultTerritoryFiltersObject = {
  "Account": {
    "attributes": {
      "displayedAccounts": {},
      "accountName": {},
      "priority": {},
      "inCRM": {},
      "industry": {},
      "employees": {},
      "annualRevenue": {},
      "technology": {},
      "location": {}
    },
    "insights": {
      "suitability": {},
      "similarWins": {},
      "upcomingRenewalDate": {},
      "opportunityCandidates": {}
    },
    "owner": {
      "accountOwner": {}
    }
  },
  "Person": {
    "attributes": {
      "name": {},
      "jobTitle": {}
    }
  },
  "Opportunity": {
    "attributes": {
      "amount": {},
      "won": {},
      "open": {}
    },
    "insights": {
      "risky": {},
      "dead": {}
    }
  }
}
