export const downloadFile = (filename: string, url: string) => {
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;
  link.click();
};

export const downloadCsvFile = (filename: string, data: string) => {
  const blob = new Blob([data], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  downloadFile(filename, url);
};

interface CSVtoJSONOptions {
  delimiter?: string; // Delimiter, default is comma ','
}

const maxSizeKiB = 200
export function csvToJson(file: File, options: CSVtoJSONOptions = {}): Promise<{ title: string[], content: any[] }> {
  const { delimiter = ',' } = options;

  return new Promise((resolve, reject) => {
    // check if the file is empty
    if (file.size === 0) {
      reject('File is empty');
      return;
    }
    // check the file below the max allowed limit
    const fileSizeInKiB = file.size / 1024;
    console.log('FileSizeInKiB=', fileSizeInKiB, 'Kib')
    if (maxSizeKiB && fileSizeInKiB > maxSizeKiB) {
      reject(`File size exceeds the limit of ${maxSizeKiB} KiB.`);
      return;
    }

    const reader = new FileReader();

    reader.onload = (event) => {
      const text = event.target?.result as string;
      if (!text) {
        reject('No content found in the file');
        return;
      }
      const lines = text.split(/\r\n|\n\"/);
      const headers = lines[0].split(delimiter);
      const titles = [] as string[];
      const json = lines.slice(1).map(line => {
        const values = line.split(delimiter);
        const jsonObject: any = {};
        headers.forEach((header, index) => {
          if (!header?.trim?.()) return;

          if (!titles.includes(header.trim()))
            titles.push(header.trim())
          jsonObject[header.trim()] = values[index]?.trim()?.replaceAll(`\"`, '')
        });
        return jsonObject;
      });
      const fileData = {
        titles: titles,
        content: json//.filter((row) => Object.keys(row).length) as any[]
      }

      if (fileData.content.length > 1)
        resolve(fileData as any)
      else
        reject('File is empty!, and does not have valid records.')
    };

    reader.onerror = () => reject('Error reading file');
    reader.readAsText(file);
  });
}


export type CsvItem = Record<string, any>;

// Define the formatter type: a function that takes a value and returns a formatted value
export type Formatter = (value: any) => any;

export function jsonToCsvFormat(
  list: CsvItem[],
  columnsNameMap: Record<string, string>,
  formatters: Record<string, Formatter> = {},
  priorities?: Record<string, number>,
): string {
  // Helper function to convert camelCase or snake_case to human-readable format
  const toHumanReadable = (str: string): string => {
    return str
      .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space before capital letters in camelCase
      .replace(/[_-]/g, ' ') // Replace underscores or dashes with spaces
      .replace(/^\w/, (c) => c.toUpperCase()); // Capitalize the first letter
  };

  // Get the headers (keys) from the first object in the list
  const headers = Object.keys(list[0]);

  // Generate the CSV header row
  const csvHeader = headers
    .map((key) => {
      const order = priorities ? priorities[key] ?? 1 : 1
      return [order, key];
    })
    .sort(([a], [b]) => (a as number) - (b as number))
    .map(([_, key]) => columnsNameMap[key] || toHumanReadable(key as string))
    .join(',');

  // Generate the CSV data rows
  const csvRows = list.map((item) => {
    return headers
      .map((key) => {
        let value = item[key];

        // Apply custom formatter if available for this key
        if (formatters[key]) {
          value = formatters[key](value);
        }

        // Handle cases where the value is an array or object
        if (Array.isArray(value)) {
          value = value.join('; '); // Convert array to a semicolon-separated string
        } else if (typeof value === 'object' && value !== null) {
          value = JSON.stringify(value); // Convert object to JSON string
        }

        // Escape any commas or quotes in the value
        if (typeof value === 'string') {
          value = `"${value.replace(/"/g, '""')}"`; // Escape double quotes by doubling them
        }
        const order = priorities ? priorities[key] ?? 1 : 1
        return [order, value];
      })
      .sort(([a], [b]) => a - b)
      .map(([_, value]) => value)
      .join(',');
  });

  // Combine header and rows
  return [csvHeader, ...csvRows].join('\n');
}

