import { Typography } from "@mui/material";
import { Stack } from "@mui/system";

interface FiltersControlProps {
  onResetFilters?: () => void;
}

function FilterControls(props: FiltersControlProps) {
  const { onResetFilters } = props;

  return (
    <Stack direction="row" gap="10px" alignItems="center">
      <Typography
        onClick={onResetFilters}
        sx={{ fontSize: "12px", cursor: "pointer", color: "#2A7AB7", userSelect: 'none' }}
      >
        Reset Filters
      </Typography>
    </Stack>
  );
}

export default FilterControls;
