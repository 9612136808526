import { FilterFilled, FilterOutlined } from "@ant-design/icons";
import { CorporateFareOutlined } from "@mui/icons-material";
import {
  Badge,
  IconButton as BaseIconButton,
  Box,
  Checkbox,
  CircularProgress,
  Stack,
  SxProps, Tooltip, TooltipProps, Typography,
} from "@mui/material";
import cx from "classnames";
import { format } from "date-fns";

import { Fragment, ReactNode, forwardRef } from "react";

import { appleCalendarIcon, googleCalendarIcon, outlookCalendarIcon } from "assets/icons";
import * as brandsIcons from "assets/images/brands";
import useToggle from "hooks/useToggle";
import { ActivityTypes, EmailActivityDirection, IActivity } from "types/api/activities";

import { isString } from "utils/varTypes";

import { CalendarService, activityActionsDynamicContentFormatter } from "./utils";

export function CircleBadge({ children, ...props }: Parameters<typeof Box>[0]) {
  return (
    <Box
      sx={{
        width: "25px",
        height: "20px",
        borderRadius: "10px",
        backgroundColor: "#2A7AB7",
        color: "#fff",
        fontSize: "12px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...props.sx,
      }}
    >
      {children}
    </Box>
  );
}

export function CheckBox(props: Parameters<typeof Checkbox>[0]) {
  return (
    <Checkbox
      sx={{
        padding: "0",
        color: "#2A7AB7",
        "& .icon.MuiBox-root ": {
          borderRadius: "2px",
          border: "1px solid #C8C8C8",
          width: "16px",
          height: "16px",
        },
        "&.Mui-checked": {
          borderRadius: "2px",
          "& .icon.MuiBox-root ": {
            borderColor: "currentColor",
          },
        },
      }}
      {...props}
    />
  );
}
type IIconButtonProps = Parameters<typeof BaseIconButton>[0] & { active?: boolean };
export function IconButton({ children, active, ...props }: IIconButtonProps) {
  return (
    <BaseIconButton
      sx={{
        bgcolor: "transparent",
        padding: "5.8px",
        color: "#fff",
        height: "24px",
        width: "24px",
        cursor: "pointer",
        "&:hover": {
          bgcolor: "#fff",
          color: "#2A7AB7",
        },
        ...(active && { bgcolor: "#fff", color: "#2A7AB7" }),
        ...props.sx,
      }}
      {...props}
      aria-label="filter toggler"
    >
      {children}
    </BaseIconButton>
  );
}

export function FilterButton({
  count,
  onClick,
  active,
  isLoading,
}: {
  count: number;
  onClick: () => void;
  active: boolean;
  isLoading?: boolean;
}) {
  const activeStyles = active
    ? { bgcolor: "#E5EFF5", color: "#2A7AB7", border:'none' }
    : { "&:hover": { bgcolor: "#F5F5F5", color: "#2A7AB1" } };
  return (
    <Badge
      badgeContent={count}
      color="primary"
      onClick={onClick}
      sx={{ "& .MuiBadge-badge": { bgcolor: "#2A7AB7" } }}
    >
      <Tooltip title={active ?  'Hide filters':'Show filters' }>
      <BaseIconButton
        disableRipple
        sx={{
          height: '40.1px',
          width:'40px',
          bgcolor: "#F5F5F5",
          color: "#AAAAAA",
          border:'1px solid #d9d9d9',
          fontSize: "22px",
          ...activeStyles,
        }}
        aria-label="filter toggler"
        >
        {isLoading ? (
          <CircularProgress size="22px" sx={{ color: "currentcolor" }} />
        ) : count ? (
          <FilterFilled />
        ) : (
          <FilterOutlined />
        )}
      </BaseIconButton>
        </Tooltip>
    </Badge>
  );
}

type IScrollableBoxProps = Parameters<typeof Box>[0] & { show: boolean; showOnHover?: boolean };
export const ScrollableBox = forwardRef((props: IScrollableBoxProps, ref) => {
  const {
    children, show, height = "100px", sx, showOnHover, ...restProps
  } = props;
  if (!show) return null;
  return (
    <Box
      ref={ref}
      sx={{
        display: "block",
        maxHeight: height,
        overflowY: "auto",
        m: 0,
        p: 0,
        pr: "8px",
        mr: "4px",
        "&::-webkit-scrollbar": {
          display: showOnHover ? "none" : "inherit",
          width: "4px",
          borderRadius: "100px",
        },
        "&::-webkit-scrollbar-track": { backgroundColor: "#F5F5F5", borderRadius: "100px" },
        "&::-webkit-scrollbar-thumb": { backgroundColor: "#BBC8D1", borderRadius: "100px" },
        "&:hover::-webkit-scrollbar": { display: "inherit" },
        "&:hover": { pr: showOnHover ? "4px" : "8px" },
        ...sx,
      }}
      {...restProps}
    >
      {children}
    </Box>
  );
});

export enum ActivityContentInteractions {
  link = "link",
  FilterByAccount = "filterByAccount",
  FilterByPerson = "filterByPerson",
}

export function ActivityContentRenderer({
  activity,
  onActionTrigger,
}: {
  activity: IActivity;
  onActionTrigger?: (action: ActivityContentInteractions, value?: string, name?: string) => void;
}) {
  const content = activityActionsDynamicContentFormatter(activity);
  if (!content) return null;
  const tooltipContent = content.map(({ label }) => label).join(" ");

  const onActionClick = (action?: ActivityContentInteractions, value?: string, name?: string) => () => {
    if (onActionTrigger && action) onActionTrigger(action, value, name);
  };

  return (
    <Tooltip title={tooltipContent} placement="left" enterNextDelay={1000}>
      <Typography gap="3px" fontSize="inherit" className="multi-line-truncate ">
        {content.map((item, index) => {
          const { label, interaction } = item;
          const labelSlices = label.split(" ").map((word, wordIndex) => String(word).trim());
          return (
            <Fragment key={`#${index}_${label}`}>
              {labelSlices.map((wordText, sliceIndex) => (
                <span
                  key={`#${index}_${sliceIndex}_${wordText}`}
                  onClick={onActionClick(
                    interaction?.type,
                    interaction?.value,
                    interaction?.name,
                  )}
                  className={cx("word-chunk", { "link-text": interaction?.value })}
                >
                  {wordText}
                </span>
              ))}
            </Fragment>
          );
        })}
      </Typography>
    </Tooltip>
  );
}

type IEmailFieldProps = {
  label?: string | ReactNode;
  value: string | ReactNode;
  tip?: string | ReactNode;
  hasBadgedValue?: boolean;
  isBolder?: boolean;
  onClick?: () => void;
  valueSx?: SxProps;
  valueSuffix?: string | ReactNode;
  suffixProps?: Parameters<typeof Box>[0];
  isHighlighted?: boolean;
  isValueLowerCase?: boolean;
  fallBackValue?: string | ReactNode;
};

export function EmailField({
  label,
  isBolder,
  onClick,
  isHighlighted,
  value,
  tip,
  valueSx,
  valueSuffix,
  suffixProps,
  isValueLowerCase = true,
  fallBackValue,
}: IEmailFieldProps) {
  const shouldHighlight = isHighlighted || value;
  const _value = isString(value) && isValueLowerCase ? String(value).toLowerCase() : value;

  return (
    <Tooltip title={tip} placement="bottom">
      <Stack
        onClick={onClick}
        flexWrap="nowrap"
        direction="row"
        alignItems="flex-start"
        gap="3px"
        sx={{
          display: "-webkit-inline-box",
          fontSize: "12px",
          userSelect: "none",
          fontWeight: "500",
          cursor: onClick ? "pointer" : "normal",
        }}
      >
        {label && <Box sx={{ color: "#AAAAAA" }}>{label}</Box>}
        <Box
          sx={{
            display: "-webkit-inline-box",
            color: shouldHighlight ? "#2A7AB7" : "#333333",
            fontWeight: isBolder ? "700" : "500",
            cursor: shouldHighlight ? "pointer" : "text",
            ...valueSx,
          }}
        >
          {_value || fallBackValue}
        </Box>
        {valueSuffix && (
          <Box component="span" sx={{ fontWeight: "500" }} {...suffixProps}>
            {valueSuffix}
          </Box>
        )}
      </Stack>
    </Tooltip>
  );
}
export function EmailBodyDisplayBox({
  body,
  supportTruncate,
  sx,
}: {
  body: string;
  supportTruncate?: boolean;
  sx?: SxProps;
}) {
  const [showFull, toggleShowFull] = useToggle(false);

  return (
    <Stack direction="column" gap="4px" sx={{ width: "100%", ...sx }}>
      <Typography
        className={cx({ "multi-line-truncate": !showFull && supportTruncate })}
        sx={{ fontSize: "12px", whiteSpace: "pre-line", wordBreak: "break-word" }}
      >
        {body}
      </Typography>
      {supportTruncate && (
        <Typography
          sx={{
            fontSize: "12px",
            color: "#1890FF",
            cursor: "pointer",
            userSelect: "none",
          }}
          onClick={() => toggleShowFull()}
        >
          {showFull ? "Read less" : "Read more"}
        </Typography>
      )}
    </Stack>
  );
}

export const emailActivityDetailsExtractor = (activity: IActivity) => {
  const {
    body, dateOfActivity, sender, subject, recipient, source, secondParty,
  } = activity;
  const hasMoreRecipients = recipient.length > 1;
  const recipientLabel = hasMoreRecipients ? ` +${recipient.length - 1}` : "";
  const recipientsEmails = recipient
    ?.map((r) => (r.address ? String(r.address).toLowerCase() : ""))
    .filter(Boolean)
    .join(",\n");
  // @note: we should consider the email direction to determine the first recipient
  const isOutbound = activity.direction === EmailActivityDirection.Outbound;
  const firstRecipient = recipient?.[0]?.name
    || recipient?.[0]?.address
    || (isOutbound ? secondParty.email : source.personName)
    || "";

  return {
    body,
    createdAt: dateOfActivity,
    fromEmail: sender?.name || sender?.address || "",
    toEmails: recipientsEmails,
    firstEmailLabel: firstRecipient,
    othersRecipientsLabel: recipientLabel,
    subject,
    firstRecipient,
    hasValidSenderName: Boolean(sender?.name),
  };
};

export function EmailDisplayBox({
  activity,
  supportTruncate,
}: {
  activity: IActivity;
  supportTruncate?: boolean;
}) {
  const isEmailActivity = activity.action === ActivityTypes.Email;

  // @todo: in the future this component should not be aware of the activity type
  if (!isEmailActivity) return null;
  const {
    body,
    createdAt,
    fromEmail,
    toEmails,
    firstEmailLabel,
    othersRecipientsLabel,
    subject,
    hasValidSenderName,
  } = emailActivityDetailsExtractor(activity);
  return (
    <Stack direction="column" gap="4px" sx={{ width: "100%", wordWrap: "break-word" }}>
      <EmailField label="Date:" value={format(new Date(createdAt) as any, "MM/dd/yyyy, hh:mm a")} />
      <EmailField
        valueSx={{ textTransform: hasValidSenderName ? "capitalize" : "none" }}
        label="From:"
        value={fromEmail}
        hasBadgedValue
      />
      <EmailField
        valueSx={{ textTransform: hasValidSenderName ? "capitalize" : "none" }}
        label="To:"
        value={`${firstEmailLabel}${othersRecipientsLabel}`}
        hasBadgedValue
        tip={toEmails}
      />
      <EmailField label="Subject:" value={subject} />
      <EmailBodyDisplayBox body={body!} supportTruncate={supportTruncate} />
    </Stack>
  );
}

export function MiniButton({ children, ...props }: Parameters<typeof Box>[0]) {
  return (
    <Box
      gap="8px"
      sx={{
        padding: "1px 8px",
        bgcolor: "#194967",
        color: "#ffffff",
        lineHeight: "20px",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        borderRadius: "4px",
        justifyContent: "center",
        "&:hover": { opacity: 0.8 },
      }}
      {...props}
    >
      {children}
    </Box>
  );
}

export enum EToolNames {
  Marketo = "marketo",
  SixSense = "6sense",
  Hubspot = "hubspot",
  Salesforce = "salesforce",
  Salesloft = "salesloft",
  Groove = "groove",
  Gong = "gong",
  GainSignal = "gain signal",
  Outreach = "outreach",
  Mixmax = "mixmax",
  SetSail = "setsail",
  Yesware = "yesware",
  Highspot = "highspot",
}

export function getToolBrandImageRender(toolName?: EToolNames) {
  const baseStyles = {
    maxWidth: "84px",
    objectFit: "contain",
    height: "30px",
  } as React.HTMLAttributes<HTMLImageElement>["style"];

  switch (String(toolName).toLowerCase()) {
    case EToolNames.Marketo:
      return (
        <img
          src={brandsIcons.marketoBrand}
          alt={toolName}
          style={{ ...baseStyles, maxWidth: "100px", height: "35px" }}
        />
      );
    case EToolNames.SixSense:
      return <img src={brandsIcons.sixSenseBrand} alt={toolName} style={baseStyles} />;
    case EToolNames.Hubspot:
      return (
        <img
          src={brandsIcons.hubspotBrand}
          alt={toolName}
          style={{ ...baseStyles, maxWidth: "54px" }}
        />
      );
    // @note: this is a temporary solution because of the bug in the backend (typo in the tool name)
    case "saleforce":
    case EToolNames.Salesforce:
      return <img src={brandsIcons.salesforceBrand} alt={toolName} style={baseStyles} />;
    case EToolNames.Gong:
      return (
        <img
          src={brandsIcons.gongBrand}
          alt={toolName}
          style={{ ...baseStyles, maxWidth: "100px", height: "35px" }}
        />
      );
    case EToolNames.GainSignal:
      return (
        <img
          src={brandsIcons.gainSignalBrand}
          alt={toolName}
          style={{ ...baseStyles, maxWidth: "100px", height: "35px" }}
        />
      );
    case EToolNames.Outreach:
      return (
        <img
          src={brandsIcons.outreachBrand}
          alt={toolName}
          style={{ ...baseStyles, maxWidth: "100px", height: "35px" }}
        />
      );
    case EToolNames.Salesloft:
      return (
        <img
          src={brandsIcons.salesloftBrand}
          alt={toolName}
          style={{ ...baseStyles, maxWidth: "90px", height: "18px" }}
        />
      );
    case EToolNames.Highspot:
      return (
        <img
          src={brandsIcons.highspotBrand}
          alt={toolName}
          style={{ ...baseStyles, maxWidth: "90px", height: "18px" }}
        />
      );
    case EToolNames.Mixmax:
      return (
        <img
          src={brandsIcons.mixmaxBrand}
          alt={toolName}
          style={{ ...baseStyles, maxWidth: "90px", height: "18px" }}
        />
      );
    case EToolNames.SetSail:
      return (
        <img
          src={brandsIcons.setSailBrand}
          alt={toolName}
          style={{ ...baseStyles, maxWidth: "90px", height: "16px" }}
        />
      );
    case EToolNames.Yesware:
      return (
        <img
          src={brandsIcons.yeswareBrand}
          alt={toolName}
          style={{ ...baseStyles, maxWidth: "90px", height: "16px" }}
        />
      );

    case EToolNames.Groove:
      return (
        <div
          style={{
            ...baseStyles,
            backgroundImage: `url(${brandsIcons.grooveBrand})`,
            backgroundRepeat: "no-repeat",
            backgroundClip: "content-box",
            backgroundPosition: "top",
            overflow: "hidden",
            backgroundSize: "100% 126%",
            width: "64px",
            height: "30px",
          }}
        />
      );
    default:
      return (
        <Stack direction="row" alignItems="center" gap="4px" style={{ color: "#1A1A1A" }}>
          <CorporateFareOutlined
            style={{ width: "20px", maxHeight: "40px", color: "currentColor" }}
          />
          <Typography
            variant="body2"
            color="currentcolor"
            fontSize="12px"
            fontWeight="500"
            lineHeight="22px"
            sx={{ fontWeight: 600 }}
          >
            {toolName || "Unknown"}
          </Typography>
        </Stack>
      );
  }
}

export function SourceLeadIcon({
  sourceTool,
  onClick,
}: {
  sourceTool?: EToolNames;
  onClick?: () => void;
}) {
  const baseStyles = {
    width: "20px",
    height: "20px",
    // borderRadius: '50%',
    objectFit: "contain",
  } as React.HTMLAttributes<HTMLImageElement>["style"];

  switch (String(sourceTool).toLowerCase()) {
    case EToolNames.Marketo:
      return <img src={brandsIcons.MarketoBrandFavicon} alt={sourceTool} style={baseStyles} />;
    case EToolNames.SixSense:
      return <img src={brandsIcons.SixSenseBrandFavicon} alt={sourceTool} style={baseStyles} />;
    case EToolNames.Hubspot:
      return <img src={brandsIcons.HubspotBrandFavicon} alt={sourceTool} style={baseStyles} />;
    // @note: this is a temporary solution because of the bug in the backend (typo in the tool name)
    case "saleforce":
    case EToolNames.Salesforce:
      return <img src={brandsIcons.salesforceBrand} alt={sourceTool} style={baseStyles} />;
    case EToolNames.Gong:
      return <img src={brandsIcons.GongBrandFavicon} alt={sourceTool} style={baseStyles} />;
    case EToolNames.GainSignal:
      return (
        <img src={brandsIcons.GainsightprodBrandFavicon} alt={sourceTool} style={baseStyles} />
      );
    case EToolNames.Outreach:
      return <img src={brandsIcons.OutreachBrandFavicon} alt={sourceTool} style={baseStyles} />;
    case EToolNames.Salesloft:
      return <img src={brandsIcons.SalesloftBrandFavicon} alt={sourceTool} style={baseStyles} />;
    case EToolNames.Highspot:
      return <img src={brandsIcons.HighspotBrandFavicon} alt={sourceTool} style={baseStyles} />;
    case EToolNames.Mixmax:
      return <img src={brandsIcons.MixmaxBrandFavicon} alt={sourceTool} style={baseStyles} />;
    case EToolNames.SetSail:
      return <img src={brandsIcons.SetsailBrandFavicon} alt={sourceTool} style={baseStyles} />;
    case EToolNames.Yesware:
      return <img src={brandsIcons.YeswareBrandFavicon} alt={sourceTool} style={baseStyles} />;
    case EToolNames.Groove:
      return <img src={brandsIcons.GrooveBrandFavicon} alt={sourceTool} style={baseStyles} />;
    default:
      return null;
  }
}

interface ITooltipContentProps {
  children: any;
  title: string | React.ReactNode;
  tooltipSx?: SxProps;
  placement?: TooltipProps["placement"];
  isOpen?: boolean;
}

export function TooltipContent({
  title,
  children,
  tooltipSx,
  placement = "top",
  isOpen,
}: ITooltipContentProps) {
  return (
    <Tooltip
      open={isOpen}
      componentsProps={{
        tooltip: {
          sx: {
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
            bgcolor: "#fff",
            color: "#1A1a1a",
            p: "10px",
            "& .MuiTooltip-arrow": {
              color: "#fff",
            },
            ...tooltipSx,
          },
        },
      }}
      title={title}
      placement={placement}
      arrow
    >
      {children}
    </Tooltip>
  );
}

function getCalenderServiceIconSrc(service?: string) {
  switch (service) {
    case CalendarService.GoogleCalendar:
      return googleCalendarIcon;
    case CalendarService.Outlook:
      return outlookCalendarIcon;
    case CalendarService.DownloadIcs:
      return appleCalendarIcon;
    default:
      return "";
  }
}

interface CalendarServiceIconProps {
  onAdd: (service: CalendarService) => () => void;
  service: CalendarService;
  tip?: string;
}

function CalendarServiceIcon(props: CalendarServiceIconProps) {
  const _onClick = props.onAdd(props.service);
  const serviceIconSrc = getCalenderServiceIconSrc(props.service);
  return (
    <Tooltip title={props.tip}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          width: "30px",
          height: "30px",
          p: "5px",
          borderRadius: "6px",
          transition: "background-color 0.5s ease",
          "&:hover": {
            bgcolor: "#0000003b",
          },
        }}
      >
        <img
          onClick={_onClick}
          src={serviceIconSrc}
          alt=""
          style={{ height: "100%", width: "100%", cursor: "pointer" }}
        />
      </Box>
    </Tooltip>
  );
}

interface AddToCalendarAvailableOptionsProps {
  onAdd: (service: CalendarService) => () => void;
}

export function AddToCalendarAvailableOptions({ onAdd }: AddToCalendarAvailableOptionsProps) {
  return (
    <Stack direction="row" gap="8px" alignItems="center">
      <CalendarServiceIcon
        service={CalendarService.GoogleCalendar}
        onAdd={onAdd}
        tip="Add to Google Calendar"
      />
      <CalendarServiceIcon
        service={CalendarService.Outlook}
        onAdd={onAdd}
        tip="Add to Outlook calendar"
      />
      <CalendarServiceIcon
        service={CalendarService.DownloadIcs}
        onAdd={onAdd}
        tip="Download ICS file"
      />
    </Stack>
  );
}
