import { SimilarWins } from "./user";

export interface AccountSimilarWins {
  name: string;
  accounts: SimilarWins[];
}

export interface SWEmployees {
  min: number;
  max: number;
  mean: number;
}

export interface SWRevenue {
  min: number;
  max: number;
  mean: number;
}

export interface SWIndustry {
  name: string;
  count: number;
}

export interface SimilarWinsStatistics {
  employees: SWEmployees;
  revenue: SWRevenue;
  industry: SWIndustry[];
}

export interface PowerRankAccount {
  accountName: string
  opportunityName: string
  type: string
  opportunityAmount: number
  closeDate: string
  accountId: string
  rank: string
}
export interface PowerRankWorkerResponse {
  results: PowerRankAccount[]
  remaining?: number
}

export interface CompanySecData {
  companyName: string
  cik: string
  ticker: string
  filing: Filing
  companyInfo: CompanyInfo
}

export interface Filing {
  risks: BusinessRisk[]
  businessPriorities: BusinessPriority[]
  lastUpdate: string
}

export interface BusinessRisk {
  title: string
  body: string
}

export interface BusinessPriority {
  title: string
  body: string
}

export interface CompanyInfo {
  name: string
  ticker: string
  cik: string
  cusip: string
  exchange: string
  isDelisted: boolean
  category: string
  sector: string
  industry: string
  sic: string
  sicSector: string
  sicIndustry: string
  famaSector: string
  famaIndustry: string
  currency: string
  location: string
  id: string
}

export enum AiWorker {
  SIMILAR_ACCOUNTS = "similarAccounts",
  PERSON_FINDER = "personFinder",
  ACCOUNT_RANK = "accountRank",
  PERSON_RECOMMENDER = "personRecommender",
  SEC_FILING = 'secFilings'
}

export interface WorkerPreCachedParams {
  worker: AiWorker
  parameters: Parameters
}

export interface Parameters {
  accountNames: string[]
  jobTitles: string[]
  accountName?: string
  askedAboutPriorities?: boolean
  askedAboutRisks?: boolean
}
