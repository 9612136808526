import { CloseCircleFilled } from "@ant-design/icons";
import { FileDownload } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  InputAdornment,
  OutlinedInput,
  Stack,
} from "@mui/material";

import { SyntheticEvent, useEffect, useMemo, useState } from "react";

import {
  FilterButton,
  TooltipContent,
} from "components/Activity.components/common";
import { useDebounceEffect } from "hooks";
import useToggle from "hooks/useToggle";
import useTerritoryFilters from "services/shooks/territoryFilters";

import { DynamicFilterT, DynFilterColumn, FiltersV } from "types/api";

import { EnabledFilterItem } from "./common";
import { HeaderDropdown } from "./HeaderDropdown";
import DataActions from "./SharedTable/DataActions";

interface TerritoryPageHeaderProps {
  onToggleFilter: () => void;
  isFilterOpen: boolean;
  updateVisibleColumns: (columns: DynFilterColumn) => void;
  columnsMeta?: DynFilterColumn;
  isTerritoryScreen?: boolean;
  onDownloadAccounts?: (option?: string) => Promise<void>;
}

const IS_FILTERS_BRIEF_LIST_ENABLED = false;
function TerritoryPageHeader(props: TerritoryPageHeaderProps) {
  const {
    onToggleFilter,
    isFilterOpen,
    updateVisibleColumns,
    isTerritoryScreen,
    onDownloadAccounts,
    columnsMeta,
  } = props;
  const [
    isLoading,
    filters,
    onFilterChange,
    selectedAccounts,
    searchQueryFilter,
    allFiltersDetails,
    accounts,
    setAllFiltersDetails,
    archiveAccounts,
    deleteAccounts,
    addAccountsToSF,
    initialState,
    isAllAccountsSelected,
  ] = useTerritoryFilters(({ state, isLoading, actions, initialState }) => [
    isLoading,
    state.filters,
    actions.onFilterChange,
    state.selectionState?.selected,
    state.filters?.Account?.attributes?.accountName?.value?.[0] || "",
    state.allFiltersDetails,
    state.accounts,
    actions.setAllFiltersDetails,
    actions.archiveAccounts,
    actions.deleteAccounts,
    actions.addAccountsToSF,
    initialState,
    state.selectionState?.isSelectAll
  ]);

  const [accountName, setAccountName] = useState("");

  const [isDownloading, , asyncLoadingToggle] = useToggle();
  const filterName = "Account.attributes.accountName.value";

  const onSearchTermChange = (searchTerm?: string) => {
    onFilterChange(filterName, [searchTerm]);
  };
  const onSearch = (event?: SyntheticEvent) => {
    const { value } = event?.target as HTMLInputElement;
    setAccountName(value);
  };
  const onResetSearchKey = () => {
    setAccountName("");
  };

  useDebounceEffect(
    () => {
      onSearchTermChange(accountName);
    },
    300,
    [accountName]
  );

  const enabledFiltersCount = useMemo(
    () =>
      Object.values(allFiltersDetails).reduce((res: number, { count }: any) => {
        const currentValue = isNaN(count) ? 0 : count;
        return (res += currentValue);
      }, 0),
    [JSON.stringify(allFiltersDetails)]
  );

  const getActiveFilterCount = (fil: FiltersV): number => {
    if (!fil) return 0;
    let count = 0;
    Object.values(fil).forEach((filter) => {
      if (filter && filter.value) {
        count += Array.isArray(filter.value)
          ? filter.value.filter(Boolean).length
          : Boolean(filter.value);
      }
    });
    return count;
  };

  const filtersCount = useMemo(() => {
    let total = 0;
    Object.values(filters as DynamicFilterT).forEach((filterKV) => {
      Object.values(filterKV).forEach((filter: FiltersV) => {
        total += getActiveFilterCount(filter);
      });
    });
    return total;
  }, [JSON.stringify(filters)]);

  const onResetFilterGroup = (name: string) => () => {
    onFilterChange(
      name,
      initialState?.filters?.[name as keyof typeof initialState.filters]
    );
  };

  const enabledMessagesFilters =
    enabledFiltersCount && IS_FILTERS_BRIEF_LIST_ENABLED ? (
      <Stack gap="10px" direction="column">
        {Object.entries(allFiltersDetails).map(
          ([name, { label }]) =>
            Boolean(label) && (
              <EnabledFilterItem
                key={name}
                label={label}
                onClick={onResetFilterGroup(name)}
              />
            )
        )}
      </Stack>
    ) : null;

  const onStartDownloadTerritoryAccounts = async () => {
    if (!onDownloadAccounts) return;

    await asyncLoadingToggle(onDownloadAccounts());
  };
  const hasSearchValue = useMemo(
    () => Boolean(searchQueryFilter ?? ""?.length),
    [searchQueryFilter ?? ""]
  );

  useEffect(() => {
    setAllFiltersDetails({ name: filterName, value: hasSearchValue ? 1 : 0 });
  }, [hasSearchValue]);

  return (
    <Stack direction="row" gap="29px">
      <Stack direction="row" alignItems="center" gap="10px">
        <TooltipContent
          title={enabledMessagesFilters}
          placement="bottom-end"
          tooltipSx={{ p: "20px", minWidth: "380px" }}
        >
          <div>
            <FilterButton
              count={filtersCount}
              onClick={onToggleFilter}
              active={isFilterOpen}
              isLoading={isLoading}
            />
          </div>
        </TooltipContent>
        <OutlinedInput
          value={accountName}
          onChange={onSearch}
          placeholder="Type an account name"
          sx={{
            fontSize: "14px",
            lineHeight: "22px",
            width: "224px",
            bgcolor: "#fff",
            borderRadius: "4px",
          }}
          endAdornment={useMemo(
            () =>
              accountName && (
                <InputAdornment
                  position="end"
                  sx={{
                    cursor: "pointer",
                    "&:hover": { opacity: "0.8", transform: "scale(1.2)" },
                  }}
                >
                  <CloseCircleFilled
                    style={{ fontSize: "13px" }}
                    onClick={onResetSearchKey}
                  />
                </InputAdornment>
              ),
            [accountName]
          )}
        />
        <DataActions
          archiveAccounts={archiveAccounts}
          deleteAccounts={deleteAccounts}
          addAccountsToSF={addAccountsToSF}
          selectedAccounts={selectedAccounts}
          accounts={accounts}
          isAllAccountsSelected={isAllAccountsSelected}
        />
      </Stack>
      <Stack
        flex={1}
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        sx={{}}
      >
        {isTerritoryScreen && (
          <Button
            onClick={onStartDownloadTerritoryAccounts}
            variant="light"
            sx={{
              borderRadius: "4px",
              color: "#00223C",
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <Stack direction="row" gap="4px" alignItems="center">
              {isDownloading ? (
                <CircularProgress
                  size="14px"
                  sx={{ m: "0 4px", color: "currentColor" }}
                />
              ) : (
                <FileDownload
                  sx={{ fontSize: "20px", color: "currentColor" }}
                />
              )}
              Export Data
            </Stack>
          </Button>
        )}
        <HeaderDropdown
          onUpdateColumnVisibility={updateVisibleColumns}
          columnsMeta={columnsMeta}
        />
      </Stack>
    </Stack>
  );
}

export default TerritoryPageHeader;
