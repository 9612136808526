import AddIcon from "@mui/icons-material/Add";

import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  DialogContent,
  DialogContentText,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import Tooltip from "components/Tooltip";

import useTerritoryFilters from "services/shooks/territoryFilters";

import { truncateText } from "utils/generics";

import ConfirmModal from "../Filters/DynamicFilterComps/ConfirmModal";
import { useSearchQuery } from "hooks";
import { deepEqual } from "../utils";
import { DynamicFilterT } from "types/api";
import { SaveViewButton } from "../common";
import { delay } from "utils/general";
import { toast } from "react-toastify";
import { compareFilterStatus } from "./utils";

const MAX_TABS_ALLOWED = 4;

function TableViews() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const [tabs, setTabs] = useState<TableViewT[]>([]);
  const [viewName, setViewName] = useState<string>("");
  const [{ id: tabId }, setQueryParams] = useSearchQuery<{ id: string }>();
  const { actions, state } = useTerritoryFilters();
  const [hasViewFiltersChanged, setHasViewFiltersChanged] = useState(false);
  const tabs = state.views;
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState<number>();

  const createView = async () => {
    const resp = await actions.createView(viewName);
    if (resp.success) {
      setViewName("");
      await delay(200);
      setHasViewFiltersChanged(false);
      // await actions.listViews()
      const viewId = resp.response?.id;
      viewId && navigate(`/territory?id=${viewId}&filters=true`);
      return;
    }
    toast.warn(`Failed to create ${viewName}`);
  };

  const deleteView = async (id: string) => {
    const resp = await actions.deleteView(id);
    if (resp.success) {
      if (tabId === id) {
        navigate("/territory?filters=true&id=");
      }
      setHasViewFiltersChanged(false);
    }
  };

  const handleTabChange = (newValue: number) => {
    if (!tabs[newValue]) {
      navigate("/territory?filters=true");
      return;
    }
    navigate(`/territory?id=${tabs[newValue].id}&filters=true`);
    setHasViewFiltersChanged(false);
  };

  const onChangeViewName = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 30) return;
    const regex = /^[a-zA-Z0-9_ ]*$/;
    if (!regex.test(e.target.value)) return;
    setViewName(e.target.value);
  };

  useEffect(() => {
    const tab = tabs.find((t) => t.id === tabId);
    if (tab) {
      setSelectedTab(tabs.indexOf(tab));
    } else {
      setSelectedTab(0);
    }
  }, [tabs, tabId]);

  const onTabChange = (tabId: string) => (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    const activeTableIndex = tabs.findIndex((t) => t.id === tabId);
    handleTabChange(activeTableIndex);
  };
  const activeTabFilters = useMemo(
    () => tabs.find((tab) => tab.id === tabId),
    [tabId, selectedTab, JSON.stringify(tabs)]
  );

  const trackFiltersChanges = async (filters: any) => {
    if (tabId) {
      // const tabFilters = JSON.parse(JSON.stringify(activeTabFilters.filters));
      // const currentFilters = JSON.parse(JSON.stringify(state.filters))
      const isEqual = compareFilterStatus(filters, activeTabFilters?.filters);
      setHasViewFiltersChanged(!isEqual);
    }
    {
      return false;
    }
  };

  useEffect(() => {
    trackFiltersChanges(state.filters);
  }, [JSON.stringify(state.filters), JSON.stringify(state.views)]);

  const onUpdateFilters = async () => {
    await actions.updateView(tabId, state.filters as DynamicFilterT);
    setHasViewFiltersChanged(false);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "99%",
          borderBottom: "1px solid lightgray",
          marginRight: "20px",
        }}
      >
        <Tabs
          value={selectedTab}
          sx={{
            width: "90%",
            borderRadius: 1,
            minHeight: "fit-content",
            "& .MuiTabs-indicator": {
              display: "none",
              border: "none",
            },
          }}
        >
          {tabs.map((tab) => {
            const hasFiltersChanged =
              tabId === tab.id ? hasViewFiltersChanged : false;
            return (
              <Tab
                key={`${tab.id}`}
                onClick={onTabChange(tab.id)}
                label={
                  <Box
                    sx={{
                      minWidth: "200px",
                      display: "flex",
                    }}
                  >
                    <button
                      type="button"
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <Tooltip
                        placement="top"
                        hide={tab.name.length < 30}
                        title={tab.name}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "left",
                            alignItems: "center",
                            gap: "5px",
                            whiteSpace: "nowrap",
                            cursor: "pointer",
                            "&:hover": {
                              color: "#00223C",
                              fontWeight: "600",
                            },
                          }}
                        >
                          <span>
                            {truncateText(
                              tab.name,
                              hasFiltersChanged ? 20 : 30
                            )}{" "}
                            {`${hasFiltersChanged ? "*" : ""}`}
                          </span>
                          {hasFiltersChanged && (
                            <SaveViewButton onSaveView={onUpdateFilters} />
                          )}
                        </Box>
                      </Tooltip>
                    </button>

                    {tab.name !== "All Accounts" && tab.id !== "" && (
                      <ConfirmModal
                        parentSx={{
                          width: "20%",
                        }}
                        label=""
                        title="Delete View"
                        hoverTitle="Please select at least one account"
                        allowHover={false}
                        message="Are you sure you want to delete this view?"
                        onConfirm={() => deleteView(tab.id)}
                        icon={<CloseIcon />}
                        labelSx={{ fontSize: "14px", whiteSpace: "nowrap" }}
                        buttonSx={{
                          marginLeft: "100%",
                          marginRight: "-20px",
                          color: "#00223C",
                          fontSize: "16px",
                          "&:hover": {
                            borderRadius: "50%",
                          },
                        }}
                        dialogTitleSx={{ fontSize: "20px" }}
                        dialogContentTextSx={{ fontSize: "16px" }}
                        cancelButtonSx={{
                          color: "black",
                          "&:hover": {
                            color: "#00223C",
                            backgroundColor: "transparent",
                          },
                        }}
                        confirmButtonSx={{
                          backgroundColor: "#00223C",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#757575",
                          },
                        }}
                        renderButton={({ onOpen }: { onOpen: () => void }) => {
                          const _onOpen = (e: React.MouseEvent) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onOpen();
                          };

                          return (
                            <Box
                              onClick={_onOpen}
                              sx={{
                                position: "absolute",
                                right: "15px",
                                top: "10px",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                                padding: "5px",
                                borderRadius: "50%",
                                backgroundColor: "transparent",
                                "&:hover": {
                                  backgroundColor: "#E5E4E2",
                                },
                              }}
                            >
                              <CloseIcon
                                sx={{
                                  fontSize: "18px",
                                }}
                              />
                            </Box>
                          );
                        }}
                      />
                    )}
                  </Box>
                }
                sx={{
                  borderRadius: "0px",
                  borderTopLeftRadius: tab.name === "All Accounts" ? "5px" : "",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "2px",
                  // width: `${tabWidth}%`,
                  width: "250px",
                  height: "40px",
                  lineHeight: "0px",

                  fontSize: "16px",
                  textAlign: "center",
                  color: "black",
                  backgroundColor: "#E5E4E2",

                  "&.Mui-selected": {
                    backgroundColor: "#E1EDFA",
                    color: "black",
                    "&:hover": {
                      backgroundColor: "#E1EDFA",
                      color: "black",
                    },
                  },
                  "&:not(.Mui-selected)": {
                    backgroundColor: "white",
                    color: "black",
                    "&:hover": {
                      color: "black",
                    },
                  },
                  "& .MuiTab-wrapper": {
                    width: "100%",
                  },
                  "& .MuiTabs-indicator": {
                    display: "none",
                    border: "none",
                  },
                  "& .MuiBox-root": {
                    justifyContent: "start",
                  },
                }}
              />
            );
          })}
        </Tabs>
        <ConfirmModal
          label={`Add view (${tabs.length}/${MAX_TABS_ALLOWED})`}
          title="Add view"
          hoverTitle={`Cannot add more than ${MAX_TABS_ALLOWED} views`}
          allowHover={tabs.length > 5}
          disableModalOpening={tabs.length >= MAX_TABS_ALLOWED}
          message="Are you sure you want to add the selected account(s) to Salesforce?"
          onConfirm={createView}
          onCancel={() => setViewName("")}
          icon={<AddIcon />}
          disableSubmit={
            !viewName || tabs.map((tab) => tab.name).includes(viewName)
          }
          error={
            tabs.map((tab) => tab.name).includes(viewName)
              ? "View name already exists"
              : ""
          }
          confirmBtnLabel="Save"
          labelSx={{
            fontSize: "14px",
            width: "130px",
          }}
          buttonSx={{ fontSize: "18px" }}
          dialogTitleSx={{ fontSize: "20px" }}
          dialogContentTextSx={{ fontSize: "16px" }}
          cancelButtonSx={{
            color: "black",
            "&:hover": {
              color: "#00223C",
              backgroundColor: "transparent",
            },
          }}
          confirmButtonSx={{
            backgroundColor: "#00223C",
            color: "white",
            "&:hover": {
              backgroundColor: "#757575",
            },
          }}
        >
          <DialogContent
            sx={{
              width: "350px",
            }}
          >
            <DialogContentText>
              <div
                style={{
                  fontSize: "12px",
                  color: "gray",
                  textAlign: "right",
                }}
              >
                {viewName.length} / 30
              </div>
              <TextField
                InputLabelProps={{ shrink: false }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "gray", // Default border color
                    },
                    "&:hover fieldset": {
                      borderColor: "gray", // Border color on hover
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "gray", // Border color on focus
                    },
                  },
                  "& .MuiInputLabel-root": {
                    color: "gray", // Default label color
                    fontSize: "14px",
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "gray", // Label color on focus
                    fontSize: "14px",
                  },
                  "& .MuiInputBase-input": {
                    // Default input color
                    color: "black",
                    fontSize: "14px",
                  },
                  "& .MuiInputBase-input:hover": {
                    // Input color on hover
                    color: "black",
                    fontSize: "14px",
                  },
                }}
                placeholder="View name"
                type="text"
                value={viewName}
                onChange={onChangeViewName}
                style={{ width: "300px" }}
              />
            </DialogContentText>
          </DialogContent>
        </ConfirmModal>
      </div>
    </div>
  );
}

export default TableViews;
