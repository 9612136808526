import {
  AimOutlined,
  CommentOutlined,
  EyeOutlined,
  FileZipOutlined,
  LineChartOutlined,
} from "@ant-design/icons";
import {
  AlternateEmailOutlined,
  AttachMoneyOutlined,
  Download,
  Event,
  Flag,
  GradingOutlined,
  PhoneAndroidOutlined,
} from "@mui/icons-material";

import { isSameDay } from "date-fns";
import { format } from "date-fns/esm";

import { ActivitySegment } from "services/activities/search";
import { IActivitySegment } from "types/ActivityStream";
import { ActivityTypes, IActivity } from "types/api/activities";
import {
  EventDetails,
  generateGoogleCalendarLink,
  generateICSFile,
  generateOffice365Link,
  generateOutlookLink,
} from "utils/calendar";
import { hasValidDate } from "utils/date";
import { formatMailLink } from "utils/email";
import { captureExceptionWithLogRocket } from "utils/userActivitiesTracker/logRocket";

import {
  callActivityContentFormatter,
  emailActivityContentFormatter,
  eventActivityContentFormatter,
  intentActivityContentFormatter,
  leadActivityContentFormatter,
  marketingEmailActivityContentFormatter,
  meetingActivityContentFormatter,
  thirdPartyDownloadActivityContentFormatter,
  webActivityContentFormatter,
  winWireActivityContentFormatter,
} from "./activitiesContentFormatters";
import { SearchBoxFiltersValue } from "./MultiSelectInput";

export const activityTypesIcons = {
  [ActivityTypes.Email]: AlternateEmailOutlined,
  [ActivityTypes.Meeting]: CommentOutlined,
  [ActivityTypes.Call]: PhoneAndroidOutlined,
  [ActivityTypes.Lead]: AimOutlined,
  [ActivityTypes.WinWire]: AttachMoneyOutlined,
  [ActivityTypes.IntentScore]: LineChartOutlined,
  [ActivityTypes.Web]: Download,
  [ActivityTypes.ThirdPartyDownload]: FileZipOutlined,
  [ActivityTypes.Event]: Event,
  [ActivityTypes.MarketingEmail]: EyeOutlined,
  [ActivityTypes.Milestone]: Flag,
  [ActivityTypes.ContentViewed]: GradingOutlined,
};
export enum ActivityGroupsEnum {
  AccountSignalsActions = "Account Signals",
  SelectedAccounts = "Accounts",
  CustomByQueryText = "Custom",
  ActionSegment = "Hidden",
}
export interface ActivityTypeOption {
  name?: string;
  value: ActivityTypes;
  group: ActivityGroupsEnum;
  icon?: React.ElementType;
}
export const activityTypeOptions = [
  { name: "Email", value: ActivityTypes.Email },
  // { name: "Meeting", value: ActivityTypes.Meeting },
  // { name: "Call", value: ActivityTypes.Call },
  { name: "Lead", value: ActivityTypes.Lead },
  // { name: "Win Wire", value: ActivityTypes.WinWire },
  { name: "Intent score", value: ActivityTypes.IntentScore },
  // { name: "Web", value: ActivityTypes.Web },
  // { name: "3rd party content download", value: ActivityTypes.ThirdPartyDownload },
  // { name: "Event", value: ActivityTypes.Event },
  // { name: "Marketing Email", value: ActivityTypes.MarketingEmail },
  // { name: "Milestone", value: ActivityTypes.Milestone },
  { name: "Content Viewd", value: ActivityTypes.ContentViewed },
].map((option) => ({
  ...option,
  group: ActivityGroupsEnum.AccountSignalsActions,
  icon: activityTypesIcons[option.value],
})) as ActivityTypeOption[];

const activitySegmentsTypes = {
  [IActivitySegment.SALES]: [
    ActivityTypes.WinWire,
    ActivityTypes.Meeting,
    ActivityTypes.Email,
    ActivityTypes.Call,
  ],
  [IActivitySegment.CUSTOMER]: [
    ActivityTypes.MarketingEmail,
    ActivityTypes.Web,
    ActivityTypes.ThirdPartyDownload,
    ActivityTypes.Lead,
    ActivityTypes.Event,
    ActivityTypes.IntentScore,
    ActivityTypes.Email,
    ActivityTypes.Call,
    ActivityTypes.Milestone,
  ],
};
export const activityTypesWithSegment = activityTypeOptions.map(
  (option) => option.value
);

// @deprecated
export function activityActionsDynamicContentFormatter(activity: IActivity) {
  const { action } = activity;
  switch (action) {
    case ActivityTypes.Email:
      return emailActivityContentFormatter(activity);
    case ActivityTypes.Lead:
      return leadActivityContentFormatter(activity);
    case ActivityTypes.Meeting:
      return meetingActivityContentFormatter(activity);
    case ActivityTypes.Call:
      return callActivityContentFormatter(activity);
    case ActivityTypes.WinWire:
      return winWireActivityContentFormatter(activity);
    case ActivityTypes.IntentScore:
      return intentActivityContentFormatter(activity);
    case ActivityTypes.Web:
      return webActivityContentFormatter(activity);
    case ActivityTypes.ThirdPartyDownload:
      return thirdPartyDownloadActivityContentFormatter(activity);
    case ActivityTypes.Event:
      return eventActivityContentFormatter(activity);
    case ActivityTypes.MarketingEmail:
      return marketingEmailActivityContentFormatter(activity);
    default:
      // @note: this will report if we have typos of unsupported activity types
      captureExceptionWithLogRocket(
        new Error(`Activity type ${action} is not supported`)
      );
      return null;
  }
}

export function activitiesSequentialGrouper(activities: IActivity[]) {
  interface ActivitiesGroup {
    [key: string]: IActivity[];
  }

  return activities.reduce((acc, activity) => {
    const { action } = activity;
    const lastInsertedGroup = acc[acc.length - 1];
    if (lastInsertedGroup && lastInsertedGroup[action]) {
      lastInsertedGroup[action].push(activity);
    } else {
      acc.push({ [action]: [activity] });
    }
    return acc;
  }, [] as ActivitiesGroup[]);
}

export function getActivityFiltersOverview(filters: SearchBoxFiltersValue) {
  const { searchText, options } = filters;
  const filtersCount = (searchText ? 1 : 0) + (options ? options.length : 0);
  return { filtersCount };
}

export function splitFiltersGroup(filters: SearchBoxFiltersValue) {
  const { options } = filters;
  const initialSet = {
    accountId: [],
    actionFilters: [],
    activitySegment: undefined,
    plainQuerySearch: "",
  } as {
    activitySegment?: ActivitySegment;
    accountIds?: string[];
    actionFilters?: string[];
    plainQuerySearch?: string;
  };
  const { accountIds, actionFilters, plainQuerySearch, activitySegment } =
    options?.reduce((acc, item) => {
      if (item.group === ActivityGroupsEnum.SelectedAccounts) {
        acc.accountIds = [
          ...(acc.accountIds || []),
          item.value as unknown as string,
        ];
      }
      if (item.group === ActivityGroupsEnum.AccountSignalsActions) {
        acc.actionFilters?.push(item.value as unknown as string);
      }
      if (item.group === ActivityGroupsEnum.CustomByQueryText) {
        acc.plainQuerySearch = item.value as unknown as string;
      }
      if (item.group === ActivityGroupsEnum.ActionSegment) {
        acc.activitySegment = item.value as unknown as ActivitySegment;
      }
      return acc;
    }, initialSet) || initialSet;
  return {
    accountIds,
    activitySegment,
    actionFilters: actionFilters?.length
      ? actionFilters
      : ([
        ActivityTypes.Email,
        ActivityTypes.Lead,
        ActivityTypes.IntentScore,
        ActivityTypes.ContentViewed,
      ] as unknown as string[]),
    plainQuerySearch,
  };
}

export const supportedActivitiesFilter = (activity: IActivity) => {
  const isValidActivity = Boolean(activity?.secondParty);
  // @todo: make a list as map of supported activity types and check if activity type is in the list instead of this long if statement
  const isActivityTypeSupported =
    activity.action === ActivityTypes.Email ||
    activity.action === ActivityTypes.Meeting ||
    activity.action === ActivityTypes.Call ||
    activity.action === ActivityTypes.ThirdPartyDownload ||
    activity.action === ActivityTypes.WinWire ||
    activity.action === ActivityTypes.Event ||
    activity.action === ActivityTypes.IntentScore ||
    activity.action === ActivityTypes.Web ||
    activity.action === ActivityTypes.MarketingEmail ||
    activity.action === ActivityTypes.Lead ||
    activity.action === ActivityTypes.Milestone ||
    activity.action === ActivityTypes.ContentViewed;

  return isValidActivity && isActivityTypeSupported;
};

export function isNewActivity(activity: IActivity, lastSeenDate: string) {
  return Boolean(
    hasValidDate(lastSeenDate) &&
    new Date(lastSeenDate) < new Date(activity.dateOfActivity)
  );
}

export function reportIfMissingToolName(activity?: IActivity) {
  if (!activity?.source?.tool) {
    captureExceptionWithLogRocket(
      Error(
        `Activity ${activity?.id || "undefined activity id!"} has no tool name`
      )
    );
  }
}

export function getActivityActionOutOfSegment(segment: IActivitySegment) {
  return activitySegmentsTypes[segment] || [];
}

export const getInfiniteScrollContainerHeight = (
  isFiltersShown: boolean,
  scrollBoxContainerDimensions: number
) => {
  let containerHeight = scrollBoxContainerDimensions;
  if (!isFiltersShown) {
    containerHeight = scrollBoxContainerDimensions + 95;
  }

  containerHeight = containerHeight < 0 ? 0 : containerHeight;
  return containerHeight;
};

export const customTimeFormator = (date: Date, formatString: string) => {
  // Today at 12:00 PM only if it's today
  if (hasValidDate(date as unknown as string) && isSameDay(date, new Date())) {
    return `Today, ${format(date, "hh:mm a")}`;
  }
  // Yesterday at 12:00 PM only if it's yesterday
  if (
    hasValidDate(date as unknown as string) &&
    isSameDay(date, new Date(Date.now() - 86400000))
  ) {
    return `Yesterday, ${format(date, "hh:mm a")}`;
  }
  return format(date, formatString);
};

export function formatForMinutesFromSeconds(seconds?: number) {
  if (seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes} min ${remainingSeconds ? `${remainingSeconds} sec` : ""
      }`;
  }
  return "0 minutes";
}

export const onGoToExternalLink = (sourceLink?: string, targetTab?: string) => {
  // open new tab with sourceLink to signal source
  const isValidLink =
    sourceLink &&
    (sourceLink.startsWith("http") ||
      sourceLink.startsWith("mailto:") ||
      sourceLink.startsWith("tel:"));
  if (!isValidLink) return;
  window.open(sourceLink, targetTab ? targetTab : "_blank");
};
export const ActivitieSubTypes = {
  marketingEmail: {
    EmailDelivered: "EmailDelivered",
    EmailUnsubscribed: "EmailUnsubscribed",
    EmailBounced: "EmailBounced",
    EmailOpened: "EmailOpened",
    EmailLinkClicked: "EmailLinkClicked",
  },
  lead: {
    LeadCreated: "LeadCreated",
    LeadStatusChanged: "LeadStatusChanged",
  },
  web: {
    WebLink: "WebLink",
  },
};

export const formatActivitySubHeadline = (activity: IActivity) => {
  const { action, subType, status, secondParty } = activity;
  let activityHeadline = "";
  const accountName = secondParty?.accountName || "";
  const activityActionName = activityTypeOptions.find(
    ({ value }) => value === action
  )?.name;

  switch (action) {
    case ActivityTypes.WinWire:
      activityHeadline = accountName;
      break;

    case ActivityTypes.Email:
      activityHeadline = subType
        ? `Email ${subType}`
        : activityActionName || "Email";
      break;

    case ActivityTypes.MarketingEmail:
      activityHeadline = subType
        ? `Marketing Email ${subType}`
        : activityActionName || "Marketing Email";
      break;

    case ActivityTypes.Lead:
      activityHeadline =
        subType === ActivitieSubTypes.lead.LeadCreated
          ? "Lead Created"
          : subType === ActivitieSubTypes.lead.LeadStatusChanged
            ? "Lead Status changed"
            : "";
      break;

    case ActivityTypes.Meeting:
      switch (subType) {
        case ActivitieSubTypes.marketingEmail.EmailDelivered:
          activityHeadline = "Meeting Invite Received";
          break;
        // fallthrough: treated the same! @ts-ignore
        case ActivitieSubTypes.marketingEmail.EmailOpened:
        case ActivitieSubTypes.marketingEmail.EmailLinkClicked:
          activityHeadline = "Meeting Invite Opened";
          break;
        case ActivitieSubTypes.marketingEmail.EmailBounced:
          activityHeadline = "Meeting Invite Bounced";
          break;
        default:
          activityHeadline = activityActionName || "";
      }
      break;
    case ActivityTypes.IntentScore: {
      const { oldIntentScore, newIntentScore } = activity;
      const hasValidScore = oldIntentScore && newIntentScore;
      let directionLabel = "";
      let directionIcon = "";

      if (hasValidScore) {
        directionLabel = hasValidScore
          ? newIntentScore > oldIntentScore
            ? "increased"
            : "decreased"
          : "";
        directionIcon = directionLabel === "increased" ? "↑" : "↓";
      }
      activityHeadline = `Intent Score ${directionLabel} ${directionIcon}`;
      break;
    }
    case ActivityTypes.Call:
      activityHeadline = "Phone Call";
      break;

    case ActivityTypes.Web:
      activityHeadline = "Web Visit";
      break;
    case ActivityTypes.ThirdPartyDownload:
      activityHeadline = "3rd Party Website  - Content Download";
      break;
    case ActivityTypes.Milestone:
      activityHeadline = "Milestone";
      break;

    case ActivityTypes.ContentViewed:
      activityHeadline = `${subType} ${status}`;
      break;

    default:
      activityHeadline = `${activityActionName} ${subType ? `(${subType})` : ""
        } ${status ? `(${status})` : ""}`;
  }

  return activityHeadline;
};

export enum ActivityCardSize {
  SMALL,
  LARGE,
}
export const activityCardWidth = {
  [ActivityCardSize.SMALL]: 156,
  [ActivityCardSize.LARGE]: 382,
};

export enum ActivityActionTypes {
  SEND_EMAIL,
  ADD_TO_CALENDAR,
}

export const emailLinkFromActivity = (activity: IActivity) => {
  const recipients =
    activity.recipient?.filter((recipient) => !recipient.cc) || [];
  const ccList = activity.recipient?.filter((recipient) => recipient.cc) || [];
  const toEmail = activity.sender;
  if (!(toEmail?.address && recipients?.length)) return;

  const link = formatMailLink({
    toEmail,
    recipients,
    ccList,
    subject: activity.subject,
  });

  return link;
};

export enum CalendarService {
  GoogleCalendar = "google",
  Outlook = "outlook",
  Office365 = "office365",
  DownloadIcs = "downloadIcs",
}

export const addToCalendarFromActivity = (
  activity: IActivity,
  targetCalendar?: CalendarService
) => {
  // subject: string, startDate: Date, endDate: Date, location: string, body: string
  const potentialParticipants = [
    ...activity.recipient,
    activity.sender?.address
      ? {
        address: activity.sender?.address,
        name: activity.sender?.name,
      }
      : null,
    activity.secondParty.email
      ? {
        address: activity.secondParty.email,
        name: activity.secondParty.personName,
      }
      : null,
  ].filter(Boolean);

  const details = {
    subject: activity.subject,
    startDate: new Date(),
    endDate: new Date(),
    location: activity.location,
    participants: potentialParticipants,
    // @note: temporarily disabled
    // body: activity.body || activity.description,
  } as unknown as EventDetails;

  switch (targetCalendar) {
    case CalendarService.GoogleCalendar:
      return generateGoogleCalendarLink(details);
    case CalendarService.Outlook:
      return generateOutlookLink(details);
    case CalendarService.Office365:
      return generateOffice365Link(details);
    case CalendarService.DownloadIcs:
      return generateICSFile(details);
  }
};
