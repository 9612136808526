import { TERRITORY_COLUMN_MAP } from "constants/territory";
import { defaultTerritoryColumnsView } from "data/territoryTable";
import { removeInvalidObjectTypes } from "pages/WhitespaceAndTerritory/components/utils";
import { GenericObject } from "services/whitespace";
import {
  DynFilterColumn,
  DynFilterObjectColumns,
  DynFiltersDetails,
  DynamicFilterT,
  FiltersV,
  ObjectTypesEnum,
  VendorProduct,
  TerritoryTableColumnsAccessors,
} from "types/api";
import { CustomFilterOperatorEnum, TerritoryFilters } from "types/api/territoryFilters";
import { getRangeDateValue, getValueFromRangeDate } from "utils/date";
import { setObjectDeep } from "utils/object";
import { Storage } from "utils/storage";
import { LookUpDataSource } from "./territoryFilters";

enum FilterConversionTypes {
  Range,
  DateRange,
  ProductVendor,
}

const filtersConversionMap = {
  "pipelineAttributeFilters.lastSalesActivity": FilterConversionTypes.DateRange,
  "pipelineAttributeFilters.lastCustomerActivity": FilterConversionTypes.DateRange,
  "pipelineAttributeFilters.nextRenewalDate": FilterConversionTypes.DateRange,
  "technographicFilters.ownedProducts": FilterConversionTypes.ProductVendor,
};
function captureAccountsIdsThatMatchProductVendor(vendors: VendorProduct[]) {
  const accountIds = vendors.map((vendor) => vendor.accountIds).flat();
  return [...new Set(accountIds)];
}

export function convertToCompatibleTerritoryFilters(filters: TerritoryFilters) {
  let _filters = { ...filters };
  for (const property in filtersConversionMap) {
    _filters = setObjectDeep(_filters, property, (filterValue: any) => {
      switch (filtersConversionMap[property as keyof typeof filtersConversionMap]) {
        case FilterConversionTypes.DateRange:
          if (!filterValue || filterValue === "all") return undefined;
          return getRangeDateValue(filterValue);

        default:
          return filterValue;
      }
    });
  }
  if (_filters?.technographicFilters?.ownedProducts) {
    _filters.accountIds = captureAccountsIdsThatMatchProductVendor(
      _filters?.technographicFilters?.ownedProducts,
    );
    delete _filters?.technographicFilters;
  }

  const hasRecordTypeFilter = Boolean(
    _filters?.customFilters?.find(({ fieldName }) => fieldName === "RecordType"),
  );
  const hasSelectionOperator = _filters?.customFilters?.find(
    ({ operator }) => operator === CustomFilterOperatorEnum.Selection,
  );

  if (hasSelectionOperator || hasRecordTypeFilter) {
    _filters?.customFilters?.forEach((ele) => {
      if (ele.operator === CustomFilterOperatorEnum.Selection) ele.operator = CustomFilterOperatorEnum.Equal;
      if (ele.fieldName === "RecordType") ele.fieldName = "RecordType.id";
    });
  }

  if (!_filters.searchQuery) {
    delete _filters.searchQuery;
  }
  const accountOwners = cleanUpUserIdsSuffix(filters.accountOwners || []);
  return { ..._filters, accountOwners };
}

export function revereseCompatibleTerritoryFilters(filters: TerritoryFilters) {
  let _filters = { ...filters };
  for (const property in filtersConversionMap) {
    _filters = setObjectDeep(_filters, property, (filterValue: any) => {
      switch (filtersConversionMap[property as keyof typeof filtersConversionMap]) {
        case FilterConversionTypes.DateRange:
          if (!filterValue) return "all";
          return getValueFromRangeDate(filterValue);

        default:
          return filterValue;
      }
    });
  }
  if (_filters?.accountIds) {
    delete _filters.accountIds;
  }
  if (_filters?.accountOwners) {
    _filters.accountOwners = _filters.accountOwners.map((id) => `${id}|parent`);
  }
  return _filters;
}

export function cleanUpUserIdsSuffix(userIds?: string[]) {
  const ids = userIds?.map((id) => id?.replace("|parent", ""));
  return [...new Set(ids)];
}

export function getPageRanges(totalItems: number, pageSize: number): [number, number][] {
  const pageRanges: [number, number][] = [];
  let left = totalItems;

  while (left > 0) {
    const offset = totalItems - left;
    pageRanges.push([offset, pageSize]);
    left -= pageSize;
  }

  return pageRanges;
}

export const formatDynamicFilters = (obj: { [key: string]: any }) => {
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      formatDynamicFilters(obj[key] as { [key: string]: any });
      if (obj[key].hasOwnProperty("value")) {
        const { value } = obj[key];
        if (value === undefined
          || value === ""
          || (Array.isArray(value) && value.length > 0 && value[0]?.length === 0)
          || (typeof value === "object" && Object.keys(value)?.length === 0)) {
          // delete obj[key].value;
          obj[key].value = undefined;
        }
      }
    }
  });
  return obj;
};

export const cleanUpDynamicFiltersValues = (obj: { [key: string]: any }) => {
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      cleanUpDynamicFiltersValues(obj[key] as { [key: string]: any });
      if (obj[key].hasOwnProperty("value")) {
        const { value } = obj[key];
        if (value === undefined
          || value === ""
          || (Array.isArray(value) && value.length > 0 && value[0]?.length === 0)
          || (typeof value === "object" && Object.keys(value)?.length === 0)) {
          delete obj[key].value;
        }
      }
    }
  });
  return obj;
}

export const getDynFilterDetails = (filters: DynFilterObjectColumns): DynFiltersDetails => {
  const result: DynFiltersDetails = {};

  Object.entries(filters).forEach(([_, objectType]) => {
    Object.entries(objectType).forEach(([filterKey, _]) => {
      result[`${filterKey}`] = {
        getLabel: (count: number) => Boolean(count) && `filter by selected account owners (${count} selected)`,
      };
    });
  });

  return result;
};

// just flatten the object makes it easier to work with for editing purposes
export const getEditableColummns = (filters: DynFilterObjectColumns): DynFilterColumn => {
  const result: DynFilterColumn = {};

  Object.entries(filters).forEach(([parentObject, objectType]) => {
    Object.entries(objectType).forEach(([filterKey, filterValue]) => {
      if (filterValue.editable) {
        result[filterKey] = { ...filterValue, parentObject };
      }
    });
  });

  return result;
};

// `${objectType}.${section}.${columnId}`
// removes previous sort if it exists and returns the new sorted column path
export const getUpdatedColumnPath = (columnId: string, filters: DynamicFilterT): string => {
  const dynFilters = removeInvalidObjectTypes(filters);
  let colPath = "";
  Object.entries(dynFilters).forEach(([objectType, objectTypeValue]) => {
    Object.entries(objectTypeValue).forEach(([section, sectionValue]) => {
      Object.entries(sectionValue).forEach(([column, columnValue]) => {
        if (Object.keys((columnValue as FiltersV)).includes("sort") && column !== columnId) {
          delete (columnValue as FiltersV).sort;
        }
        if (column === columnId) {
          colPath = `${objectType}.${section}.${column}`;
          return colPath;
        }
      });
    });
  });
  return colPath;
};

export const resetFiltersFormat = (_obj: GenericObject): DynamicFilterT => {
  const obj = { ..._obj };
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      resetFiltersFormat(obj[key] as GenericObject);
      if ("value" in obj[key]) {
        delete obj[key].value;
      }
    }
  });
  const hasObjectTypes: boolean = Object.keys(ObjectTypesEnum)
    .every((key) => key in obj);

  const invalidObjectTypes: boolean = hasObjectTypes && Object.keys(ObjectTypesEnum)
    .every((key) => !Object.keys(obj[key as keyof typeof obj]).length);

  return invalidObjectTypes ? _obj as DynamicFilterT : obj as DynamicFilterT;
};

export const nonDisplayableCols = [
  "source",
  "website",
  TerritoryTableColumnsAccessors.annualRecurringRevenue,
  TerritoryTableColumnsAccessors.accountStatus,
  TerritoryTableColumnsAccessors.alexaRanking,
  TerritoryTableColumnsAccessors.renewals,
  TerritoryTableColumnsAccessors.lastSignalDate,
  TerritoryTableColumnsAccessors.lastSalesActivity,
];

export const cleanedColumnsToView = (columns?: DynFilterColumn) => {
  if (!columns) return undefined
  const entries = Object.entries(columns).map(([key, value]) => {
    return [key, { isViewed: value.isViewed }]
  })
    .filter(([key]) => key !== TerritoryTableColumnsAccessors.selection)
  const dummyColumns = nonDisplayableCols.map((key) => [key, { isViewed: false, }] as [string, DynFilterColumn[any]])
  const allEntries = [...entries, ...dummyColumns].map(([key, value]) => {
    return [key, { isViewed: false, ...(value as any) }]
  });

  return Object.fromEntries(allEntries)
}

export const addSelectionColumnToTerritoryColumns = (columns: DynFilterColumn) => {
  if (!columns) return undefined
  const entries = Object.entries(columns)
    .filter(([key]) => !nonDisplayableCols.includes(key))
  entries.push([TerritoryTableColumnsAccessors.selection, { isViewed: true, editable: false, label: "Selection", sortable: false } as DynFilterColumn[any]])
  return Object.fromEntries(entries)
}

type CleanObject<T> = {
  [K in keyof T]: T[K] extends object ? (keyof T[K] extends never ? never : CleanObject<T[K]>) : T[K];
};

export function cleanObject<T extends object>(obj: T): CleanObject<T> | undefined {
  const result = {} as CleanObject<T>;

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];

      if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
        const cleanedValue = cleanObject(value as unknown as T);

        if (cleanedValue && Object.keys(cleanedValue).length > 0) {
          (result as any)[key] = Object.assign({}, cleanedValue);
        }
      } else {
        (result as any)[key] = value;
      }
    }
  }

  return Object.keys(result).length > 0 ? result : undefined
}

interface DataSourceObject {
  external: any;
  crm: any;
}

interface Payload {
  [key: string]: any;
}

export function flattenAccountPayloadDifferentSources(payload: Payload, dataSource: LookUpDataSource): Payload {
  // Create a new object to store the flattened result
  const flattened: Payload = {};

  // Iterate over each key in the payload
  for (const key in payload) {
    const value = payload[key];

    // If the value is an object and has 'external' and 'crm' fields, flatten it based on dataSource
    if (typeof value === 'object' && value !== null && 'external' in value && 'crm' in value) {
      flattened[key] = (value as DataSourceObject)[dataSource];
    } else {
      // Otherwise, just copy the value as it is
      flattened[key] = value;
    }
  }

  return flattened;
}
