import { UploadFileOutlined } from "@mui/icons-material";
import {
  Button, CircularProgress, Stack, Tooltip, Typography,
} from "@mui/material";

import { Box } from "@mui/system";
import { useSearchQuery } from "hooks";
import React, { useCallback, useRef } from "react";
import { toast } from "react-toastify";
import { AiWorker, WorkerPreCachedParams } from "types/api";
import { csvToJson } from "utils/file";

import AccountAutoCompleteField from "../shared/AccountAutoCompleteField";
import ColumnSelectorModal, { useCsvColumnSelector } from "../shared/CsvColumnSelector";
import { useClipboardState, useWorkerPrePopulateParams } from "../shared/hooks";

interface PowerRankFormProps {
  onFind: (accounts: string[], jobs: string[]) => void;
  onResetErrorMessage: () => void;
  isLoading?: boolean;
  onCancelRequest: () => void;
  onResetForm: () => void;
}

function PowerRankForm(props: PowerRankFormProps) {
  const { onFind, onResetErrorMessage, isLoading, onCancelRequest } = props;
  const [accounts, setAccounts] = React.useState<string[]>([]);
  const { fileBody, isCsvPreviewOpen, onClickUpload, onCloseCsvPreview, renderInput: renderFileBrowserInput } = useCsvColumnSelector()

  const canRankAccounts = accounts.length > 0 && !isLoading;

  const onUpdateAccounts = (values: string[]) => {
    setAccounts(values);
    onResetErrorMessage();
  };

  const [{ id: preStoredParamsId }] = useSearchQuery<{ id: string }>();
  useWorkerPrePopulateParams((params: WorkerPreCachedParams) => {
    if (params.worker !== AiWorker.ACCOUNT_RANK) return;
    const { accountNames } = params.parameters;
    if (Array.isArray(accountNames) && accountNames.length) {
      setAccounts(accountNames)
    }
    onFind(accountNames, []);
  }, preStoredParamsId)

  const onRankAccounts = () => {
    if (canRankAccounts) {
      onFind(accounts, []);
    }
  };

  const onResetForm = () => {
    setAccounts([]);
    props.onResetForm?.();
  }


  const onColumnNameSelected = useCallback((selectedColumn: string) => {
    const accountNames = fileBody.content.map((rowObject: object) => rowObject[selectedColumn as keyof typeof rowObject]);
    if (!Array.isArray(accountNames) && accountNames.length > 0) return;
    setAccounts(accountNames)
    onFind(accountNames, []);
  }, [fileBody]);

  const updateAccountsOnPaste = (accountsAsText: string) => {
    if (!accountsAsText) return;
    const accounts = accountsAsText.split('\n').filter((acc) => (acc && acc.trim?.() && acc.length > 2))
    setAccounts(accounts)
  }

  useClipboardState({ onPaste: updateAccountsOnPaste });

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      <Typography sx={{ fontSize: "12px", color: "#333333" }}>
        Enter an account or multiple accounts to get them ranked.
      </Typography>
      <div style={{ display: "flex", gap: "10px", alignItems: "start" }}>
        <AccountAutoCompleteField maxDisplayed={8} values={accounts} placeholder="Account Name" onUpdate={onUpdateAccounts} />
        <Tooltip title={!canRankAccounts ? "Please add at least one account and one job title" : ""} arrow>
          <div>
            <Button sx={{ height: "42px", gap: "5px" }} variant="contained" color="primary" disabled={!canRankAccounts} onClick={onRankAccounts}>
              Search
              {isLoading && <CircularProgress size={12} color="inherit" sx={{ display: isLoading ? "block" : "none" }} />}
            </Button>
          </div>
        </Tooltip>
        {isLoading ? (
          <Button sx={{ height: "42px" }} variant="text" onClick={onCancelRequest}>Cancel</Button>
        ) : Boolean(accounts.length) && (
          <Button sx={{ height: "42px" }} variant="text" onClick={onResetForm}>Reset</Button>
        )}
        {!Boolean(accounts.length) && (
          <Stack flex={1} direction='row' justifyContent='flex-start' alignItems='flex-end' height='100%'>
            <Box
              onClick={onClickUpload}
              sx={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '12px',
                gap: '5px',
                userSelect: 'none',
                textTransform: 'none',
                color: '#1890ffe6',
                '&:hover': { textDecoration: 'underline' }, cursor: 'pointer'
              }}
            >
              <span>Upload a CSV file instead</span>
              <UploadFileOutlined sx={{ fontSize: '16px' }} />
            </Box>
          </Stack>
        )}
        {renderFileBrowserInput}
      </div>
      {isCsvPreviewOpen &&
        <ColumnSelectorModal
          fileBody={fileBody}
          isOpen={isCsvPreviewOpen}
          onClose={onCloseCsvPreview}
          onColumnNameSelected={onColumnNameSelected}
        />}
    </Box>
  );
}

export default PowerRankForm;
