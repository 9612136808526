import { CloseCircleOutlined } from "@ant-design/icons";
import { CenterFocusStrongOutlined } from "@mui/icons-material";
import {
  Autocomplete, CircularProgress, Stack, TextField, Typography,
} from "@mui/material";
import {
  Fragment, KeyboardEvent, useEffect, useRef, useState,
} from "react";

import useTerritoryFilters from "services/shooks/territoryFilters";

import { SelectItem } from "../Firmographics";

type Props = {
  valuePath: string;
  label: string;
  hasActiveFilters: boolean;
  values: string[];
  options: string[];
  isLoading?: boolean;
  onSearch?: (searchKey: string) => void;
}

function InputFilter({
  valuePath,
  label,
  hasActiveFilters,
  values: initialValues,
  options,
  onSearch,
  isLoading
}: Props) {
  const getPlaceholder = () => {
    let placeholder = `Enter ${label.toLowerCase()}`;
    if (label === "Technology") {
      placeholder = "Enter Technology or Category";
    }
    return placeholder;
  };

  const placeholder = getPlaceholder();
  const { actions } = useTerritoryFilters();
  const { onFilterChange } = actions;

  const [value, setValue] = useState<string>("");
  const [values, setValues] = useState<string[]>(initialValues);

  const [filteredOptions, setFilteredOptions] = useState(
    options.filter((option) => !values.includes(option)),
  );

  const [errorMessage, setErrorDetails] = useState<string>("");

  const textFieldRef = useRef<HTMLInputElement>(null);

  const filterOptions = (inputValue: string) => {
    const newOptions = options
      .filter((option) => option.toLowerCase()
        .includes(inputValue.toLowerCase()));
    setFilteredOptions(newOptions);
  };

  useEffect(() => {
    setFilteredOptions(options)
  }, [JSON.stringify(options)])

  const resetAndBlurInput = () => {
    if (textFieldRef.current) {
      textFieldRef.current.blur();
      textFieldRef.current.value = "";
    }
    setValue("");
    filterOptions("");
    setErrorDetails("");
  };

  const onReset = () => {
    resetAndBlurInput();
    onFilterChange(valuePath, "");
    setValues([]);
  };

  // @TODO: handle more error cases
  const getErrorDetails = (text: string) => {
    if (!text) {
      return "Please enter a value";
    }
    if (values.includes(text)) {
      return "Value already added";
    }
    return "";
  };

  const saveNewFilter = (val: string) => {
    const errorDetails = getErrorDetails(val);
    const isValidValue = !errorDetails;
    setErrorDetails(errorDetails);

    if (isValidValue) {
      const newValues = [...values, val];
      onFilterChange(valuePath, newValues);
      setValues(newValues);
    }
    resetAndBlurInput();
  };

  const onChange = (_event: any, valSelected: string | null) => {
    if (!valSelected) {
      return;
    }
    resetAndBlurInput();
    saveNewFilter(valSelected);
  };

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === "Escape") {
      setValue("");
      resetAndBlurInput();
    }
    if (e.key === "Enter") {
      saveNewFilter(value);
    }
  };

  const onRemoveSelectedOption = (toRemove: string) => () => {
    const filteredValues = values.filter((val) => val !== toRemove);
    onFilterChange(valuePath, filteredValues);
    setValues(filteredValues);
  };

  useEffect(() => {
    setValues(initialValues);
  }, [JSON.stringify(initialValues)]);

  const onSearchForValue = (event: any) => {
    onSearch?.(event.target.value)
    setValue(event.target.value)
  }

  return (
    <Fragment key={values.join(",")}>
      <div
        style={{
          alignItems: "center",
          color: "gray",
          display: "flex",
          fontSize: "14px",
          justifyContent: "space-between",
          marginBottom: "5px",
        }}
      >
        {label}
        {hasActiveFilters && (
          <Typography
            onClick={onReset}
            fontWeight="500"
            fontSize="11px"
            color="#2A7AB7"
            sx={{ cursor: "pointer" }}
          >
            Reset
          </Typography>
        )}
      </div>

      <Autocomplete
        freeSolo
        options={filteredOptions.slice(0, 100)}
        value={value}
        onChange={onChange}
        clearIcon={(isLoading) ? <CircularProgress size={15} /> : Boolean(value) ? <CloseCircleOutlined size={15} /> : null}
        renderInput={(params) => (
          <TextField
            {...params}
            inputRef={textFieldRef}
            sx={{
              width: "100%",
              "& .MuiInputBase-input": {
                fontSize: "13.5px",
              },
            }}
            placeholder={placeholder}
            type="text"
            onChange={onSearchForValue}
            error={!!errorMessage}
            autoComplete="off"
            name={valuePath}
            onKeyDown={onKeyDown}
          />
        )}
      />

      {errorMessage && (
        <div style={{ color: "#e74c3c", fontSize: 12, marginTop: 6 }}>{errorMessage}</div>
      )}

      <Stack key={values.join(", ")} gap="5px" direction="column">
        {values.map((val) => (
          <SelectItem
            key={val}
            label={val}
            onClick={onRemoveSelectedOption(val)}
          />
        ))}
      </Stack>
    </Fragment>
  );
}

export default InputFilter;
