import { useAuth0 } from "@auth0/auth0-react";

import React, { createContext } from "react";

import useDebounceEffect from "hooks/useDebounceEffect";
import useActivityFeedOpenActionListener from "hooks/useNotification";
// import useEmployeeTree from "services/shooks/EmployeeHierarchy";
// import usePipelineState from "services/shooks/pipeline";
import useUserSettings from "services/shooks/settings";
// import useTerritoryAccounts from "services/shooks/territory";
import useLastSignalCount from "services/shooks/useNewActivitiesSignals";

type AppContextProps = any;

const AppContext = createContext<AppContextProps>({} as AppContextProps);

const useAppInitializers = () => {
  const { isAuthenticated, user } = useAuth0<User>();
  // const { actions: pipelineActions } = usePipelineState();
  const { actions: userSettingActions } = useUserSettings();
  // const { actions: territoryAccountActions } = useTerritoryAccounts();
  const { actions: activitiesLastSignalActions } = useLastSignalCount();

  // @note: this listen to the open actions for the activity feed and update the seen date for the activities

  useActivityFeedOpenActionListener();
  useDebounceEffect(
    () => {
      if (!isAuthenticated && !user?.SFUserId) return;
      // pipelineActions.initialize();
      userSettingActions.initialize();
      // territoryAccountActions.initialize();
      activitiesLastSignalActions.initialize();
    },
    50,
    [isAuthenticated, user?.SFUserId],
  );
};

function AppContextProvider({ children }: any) {
  useAppInitializers();
  return <AppContext.Provider value={{}}>{children}</AppContext.Provider>;
}

export { AppContext, AppContextProvider };
