import { Box } from "@mui/material";
import { Stack } from "@mui/system";

import { IAccountFilAllClientsData } from "types/accountFitTooltip";
import { PipelineSignalEnums } from "types/api/territoryFilters";

import AccountFitTooltip from "./AccountFitTooltip";

interface ISignalStyles {
  [key: string]: {
    borderRadius?: string;
    width?: string;
    height?: string;
    backgroundColor: string;
    margin?: string;
  };
}

const signalSx = {
  width: "8.3px",
  height: "8.3px",
};
export const signalLabels = {
  [PipelineSignalEnums.Strong]: "Strong",
  [PipelineSignalEnums.Medium]: "Medium",
  [PipelineSignalEnums.Low]: "Low",
};
export const signalStyles: ISignalStyles = {
  [PipelineSignalEnums.Strong]: {
    backgroundColor: "#358700",
  },
  [PipelineSignalEnums.Medium]: {
    borderRadius: "50%",
    backgroundColor: "#61ABF2",
  },
  [PipelineSignalEnums.Low]: {
    borderRadius: "50%",
    backgroundColor: "#FFA900",
  },
};

export const signalReversed = {
  'Low': PipelineSignalEnums.Low,
  'Medium': PipelineSignalEnums.Medium,
  'High': PipelineSignalEnums.Strong,
}

export function PipelineSignal({
  signal,
  accountFitData,
  hasTooltip,
}: {
  signal: PipelineSignalEnums;
  accountFitData?: IAccountFilAllClientsData;
  hasTooltip?: boolean;
}) {
  const statusSx = { ...signalSx, ...(signal ? signalStyles[signal] : {}) };
  const signalLabel = signalLabels[signal] || "--";

  return (
    <AccountFitTooltip
      value={signal}
      color={signalStyles[signal]?.backgroundColor}
      accountFitData={accountFitData}
    >
      <Stack direction="row" gap="5px" alignItems="center">
        {signal && <Box sx={statusSx} />}
        <Box sx={{ textTransform: "capitalize" }}>{signalLabel}</Box>
      </Stack>
    </AccountFitTooltip>
  );
}
