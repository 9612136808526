import Grid from "@mui/material/Grid";
import { Box } from "@mui/system";

import { Navigate, Route, Routes } from "react-router-dom";

import { Page } from "components/layout";
import Navbar from "components/layout/Navbar";

import UserFeatureAccessWrapper from "components/UserFeatureAccessWrapper";
import { botsListSet, WorkerNameEnum } from "data/bots";
import { FeatureGroupSet } from "data/userPermissionsMap";

import { PeopleFinder, SecWorker, SimilarWinsWorker } from "./workers";

import AIWorkerCard from "./workers/shared/WorkerCard";
import PowerRankWorkerWrapper from "./workers/PowerRank";
import ListBuilderWorkerWrapper from "./workers/ListBuilder";
import PersonRecommenderWrapper from "./workers/PersonRecommender";

function AiWorkersPage() {
  return (
    <Page

      hasFooter={false}
      renderCustomHeader={() => (
        <Navbar isNotificationBellShown={false} />
      )}
    >
      <UserFeatureAccessWrapper groupName={FeatureGroupSet.VIEW_AI_WORKERS_PAGE}>
        <div style={{ backgroundColor: "#fafafb", display: "flex", flex: 1 }}>
          <Routes>
            <Route
              path="/"
              element={(
                <Box sx={{ p: "20px", margin: "0 auto" }}>
                  <Grid container spacing={2} justifyContent="start">
                    {botsListSet.map((bot, index) => (
                      <Grid item key={index}>
                        <AIWorkerCard bot={bot} />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              )}
            />
            <Route path={WorkerNameEnum.PERSON_FINDER} element={<PeopleFinder />} />
            <Route path={WorkerNameEnum.SIMILAR_ACCOUNTS} element={<SimilarWinsWorker />} />
            <Route path={WorkerNameEnum.SIMILAR_WINS} element={<SimilarWinsWorker widget={WorkerNameEnum.SIMILAR_WINS} />} />
            <Route path={WorkerNameEnum.POWER_RANK} element={<PowerRankWorkerWrapper />} />
            <Route path={WorkerNameEnum.SEC_WORKER} element={<SecWorker />} />
            <Route path={WorkerNameEnum.LIST_BUILDER} element={<ListBuilderWorkerWrapper />} />
            <Route path={WorkerNameEnum.PERSON_RECOMMENDER} element={<PersonRecommenderWrapper />} />
            <Route path="/*" element={<Navigate to="/" />} />
          </Routes>
        </div>
      </UserFeatureAccessWrapper>
    </Page>
  );
}

export default AiWorkersPage;
