import { Box } from "@mui/material";

import React, { KeyboardEvent } from "react";

import { CheckMarkIcon } from "assets/icons";
import useWhenClickOutside from "hooks/useWhenClockOutside";
import { getDomainFaviconFromEmail } from "utils/email";

import CustomTextField from "./CustomTextField";
import { useAccountSearch } from "../PeopleFinder/hooks";

interface AutoCompleteCustomFieldProps {
  values: string[];
  placeholder: string;
  onUpdate: (values: string[]) => void;
  disable?: boolean;
  maxDisplayed?: number
  allowedSelectionNumber?: number;
}

function AccountAutoCompleteField(props: AutoCompleteCustomFieldProps) {
  const { values, onUpdate, disable, maxDisplayed, allowedSelectionNumber } = props;
  const { accounts, searchKey, onSearchKeyUpdates } = useAccountSearch();
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  useWhenClickOutside(wrapperRef, () => onSearchKeyUpdates(""));

  const onSearchKeyChange = (key: string) => {
    if (disable) return
    onSearchKeyUpdates(key);
  };

  const onAccountSelect = (account: { name: string, domain: string }) => () => {
    if (disable) return;
    if (allowedSelectionNumber && values.length >= allowedSelectionNumber) {
      console.warn('Trying to add more than allowed')
      return;
    }

    const isExist = values.find((value) => value === account.name);
    if (isExist) {
      return onUpdate(values?.filter(val => val !== account.name) || [])
    }
    onUpdate([...values, account.name]);
  };

  const onEnterPressed = () => {
    if (accounts?.[0]) {
      onAccountSelect(accounts?.[0])()
    }
  }


  return (
    <Box sx={{ position: "relative" }} ref={wrapperRef} >
      <CustomTextField
        values={values}
        onChange={onSearchKeyChange}
        value={searchKey}
        placeholder="Account Name"
        onUpdate={onUpdate}
        enableReset
        maxDisplayed={maxDisplayed}
        disable={disable}
        onEnter={onEnterPressed}
        renderOptions={Boolean(accounts.length) && (
          <Box
            sx={{
              position: "absolute",
              marginTop: "3px",
              top: "100%",
              left: 0,
              width: "80.5%",
              background: "white",
              boxShadow: "0 3px 10px rgba(0, 0, 0, 0.2)",
              borderRadius: "5px",
              zIndex: 1000,
            }}
          >
            {accounts.map((account, index) => {
              const isSelected = values.find((value) => value === account.name);
              const companyLogo = getDomainFaviconFromEmail(`sample@${account.domain}`);
              return (
                <Box
                  key={index}
                  sx={{
                    padding: "10px",
                    borderBottom: "1px solid #cccccc5d",
                    cursor: "pointer",
                    userSelect: "none",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "12px",
                    "&:hover": {
                      background: "#f9f9f9",
                    },
                  }}
                  onClick={onAccountSelect(account)}
                >
                  <img
                    src={companyLogo}
                    alt={account.domain}
                    style={{
                      width: "20px", height: "20px", borderRadius: "50%", marginRight: "10px",
                    }}
                  />
                  <span style={{ flex: 1 }}>
                    {account.name}
                  </span>
                  {isSelected && <CheckMarkIcon style={{ fontSize: "12px", color: "#3498db", marginRight: "5px" }} />}
                </Box>
              );
            })}
          </Box>
        )}
      />
    </Box>
  );
}

export default AccountAutoCompleteField;
