import { Stack, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";

import {
  PipelineSignal,
  signalStyles,
} from "components/AccountTables/PipelineSignal";
import useTerritoryFilters from "services/shooks/territoryFilters";
import { PipelineSignalEnums } from "types/api/territoryFilters";
import { overrideHexColorTransparency } from "utils/colors";

interface PipelineSignalBadge {
  signal: PipelineSignalEnums;
  activeSignals: PipelineSignalEnums[];
  onSelect: (signal: PipelineSignalEnums) => void;
}

function PipelineSignalBadge({
  signal,
  activeSignals,
  onSelect,
}: PipelineSignalBadge) {
  const isActive = activeSignals?.includes(signal);
  const _onSelect = () => {
    onSelect?.(signal);
  };
  const activeStyle = signalStyles[signal] ?? {};
  return (
    <Stack
      onClick={_onSelect}
      sx={{
        p: "2px 6px",
        cursor: "pointer",
        borderRadius: "5px",
        border: "1px solid #f0f0f0",
        transition: "transform .3s ease",
        borderColor: isActive ? activeStyle.backgroundColor : "#f0f0f0",
        bgcolor: isActive
          ? overrideHexColorTransparency(activeStyle.backgroundColor, {
              a: 0.1,
            })
          : "transparent",
        "&:hover": {
          borderColor: activeStyle.backgroundColor,
          transform: "scale(1.1)",
        },
      }}
    >
      <PipelineSignal signal={signal} />
    </Stack>
  );
}

type PipeLineSignalFilterProps = {
  title: string;
  value?: PipelineSignalEnums[];
  valuePath?: string;
};

function PipelineSignalFilter({
  title,
  value = [],
  valuePath = "",
}: PipeLineSignalFilterProps) {
  const filterName = valuePath || "pipelineSignals";

  const { actions } = useTerritoryFilters();
  const { onFilterChange, setAllFiltersDetails } = actions;

  const [pipelineSignals, setPipelineSignals] = useState(value || []);
  const hasSignal = useMemo(
    () => Boolean(pipelineSignals?.length),
    [pipelineSignals?.length]
  );
  const onSignalChange = (signal: PipelineSignalEnums) => {
    const isExist = pipelineSignals?.includes(signal);
    let targetSignals = [...pipelineSignals];
    if (isExist)
      targetSignals = targetSignals.filter(
        (targetSignal) => targetSignal !== signal
      );
    else targetSignals = [...targetSignals, signal];
    setPipelineSignals(targetSignals);
  };

  useEffect(() => {
    if (value.length >= 0) setPipelineSignals(value);
  }, [value]);

  useEffect(() => {
    if (Array.isArray(pipelineSignals))
      onFilterChange(filterName, pipelineSignals);
  }, [pipelineSignals]);

  useEffect(() => {
    setAllFiltersDetails({ name: filterName, value: hasSignal ? 1 : 0 });
  }, [hasSignal]);

  const onReset = () => {
    setPipelineSignals([]);
    setAllFiltersDetails({ name: filterName, value: 0 });
  };

  return (
    <Stack direction="column" gap="8px" sx={{ borderRadius: "4px" }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography fontSize="14px" color="#757575" borderRadius="4px">
          {title}
        </Typography>
        <Typography
          onClick={onReset}
          fontWeight="500"
          fontSize="11px"
          color="#2A7AB7"
          sx={{ cursor: "pointer" }}
        >
          Reset
        </Typography>
      </Stack>
      <Stack
        justifyContent="center"
        gap="10px"
        direction="row"
        p="15px 12px"
        bgcolor="#FAFAFA"
        borderRadius="5px"
      >
        <PipelineSignalBadge
          onSelect={onSignalChange}
          signal={PipelineSignalEnums.Strong}
          activeSignals={pipelineSignals}
        />
        <PipelineSignalBadge
          onSelect={onSignalChange}
          signal={PipelineSignalEnums.Medium}
          activeSignals={pipelineSignals}
        />
        <PipelineSignalBadge
          onSelect={onSignalChange}
          signal={PipelineSignalEnums.Low}
          activeSignals={pipelineSignals}
        />
      </Stack>
    </Stack>
  );
}

export default PipelineSignalFilter;
