import { Stack, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";

import { signalStyles } from "components/AccountTables/PipelineSignal";
import useTerritoryFilters from "services/shooks/territoryFilters";
import { AccountPriorityEnums } from "types/api/territoryFilters";
import { overrideHexColorTransparency } from "utils/colors";
import { Box } from "@mui/system";

interface PriorityBadge {
  signal: AccountPriorityEnums;
  activePriorities: AccountPriorityEnums[];
  onSelect: (priority: AccountPriorityEnums) => void;
}

export const PriorityBadgeSx = {
  [AccountPriorityEnums.A]: { background: "#004E87" },
  [AccountPriorityEnums.B]: { background: "#2A7AB7" },
  [AccountPriorityEnums.C]: { background: "#61ABF2" },
  [AccountPriorityEnums.D]: { background: "#61ABF2" },
};

function PriorityBadge({ signal, activePriorities, onSelect }: PriorityBadge) {
  const isActive = activePriorities?.includes(signal);
  const _onSelect = () => {
    onSelect?.(signal);
  };
  const activeStyle = signalStyles[signal] ?? {};
  return (
    <Stack
      onClick={_onSelect}
      sx={{
        p: "5px 12px",
        cursor: "pointer",
        borderRadius: "5px",
        border: "1px solid #f0f0f0",
        transition: "transform .3s ease",
        borderColor: isActive ? activeStyle.backgroundColor : "#f0f0f0",
        bgcolor: isActive
          ? overrideHexColorTransparency(activeStyle.backgroundColor, {
              a: 0.1,
            })
          : "transparent",
        "&:hover": {
          borderColor: activeStyle.backgroundColor,
          transform: "scale(1.1)",
        },
        color: isActive ? "white" : PriorityBadgeSx[signal].background,
        fontWeight: "700",
        width: "20px",
        height: "24px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: !isActive ? "white" : PriorityBadgeSx[signal].background,
      }}
    >
      <Box>{signal}</Box>
    </Stack>
  );
}

type PriorityProps = {
  title: string;
  value?: AccountPriorityEnums[];
  valuePath?: string;
};

function PriorityFilter({ title, value = [], valuePath = "" }: PriorityProps) {
  const filterName = valuePath || "priority";

  const { actions } = useTerritoryFilters();
  const { onFilterChange, setAllFiltersDetails } = actions;

  const [priorities, setPipelineSignals] = useState(value || []);
  const hasSignal = useMemo(
    () => Boolean(priorities?.length),
    [priorities?.length]
  );
  const onPriorityChange = (signal: AccountPriorityEnums) => {
    const isExist = priorities?.includes(signal);
    let targetSignals = [...priorities];
    if (isExist)
      targetSignals = targetSignals.filter(
        (targetSignal) => targetSignal !== signal
      );
    else targetSignals = [...targetSignals, signal];
    setPipelineSignals(targetSignals);
  };

  useEffect(() => {
    if (value.length >= 0) setPipelineSignals(value);
  }, [value]);

  useEffect(() => {
    if (Array.isArray(priorities)) onFilterChange(filterName, priorities);
  }, [priorities]);

  useEffect(() => {
    setAllFiltersDetails({ name: filterName, value: hasSignal ? 1 : 0 });
  }, [hasSignal]);

  const onReset = () => {
    setPipelineSignals([]);
    setAllFiltersDetails({ name: filterName, value: 0 });
  };

  return (
    <Stack direction="column" gap="8px" sx={{ borderRadius: "4px" }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography fontSize="14px" color="#757575" borderRadius="4px">
          {title}
        </Typography>
        <Typography
          onClick={onReset}
          fontWeight="500"
          fontSize="11px"
          color="#2A7AB7"
          sx={{ cursor: "pointer" }}
        >
          Reset
        </Typography>
      </Stack>
      <Stack
        justifyContent="center"
        gap="10px"
        direction="row"
        p="15px 12px"
        bgcolor="#FAFAFA"
        borderRadius="5px"
      >
        <PriorityBadge
          onSelect={onPriorityChange}
          signal={AccountPriorityEnums.A}
          activePriorities={priorities}
        />
        <PriorityBadge
          onSelect={onPriorityChange}
          signal={AccountPriorityEnums.B}
          activePriorities={priorities}
        />
        <PriorityBadge
          onSelect={onPriorityChange}
          signal={AccountPriorityEnums.C}
          activePriorities={priorities}
        />
        <PriorityBadge
          onSelect={onPriorityChange}
          signal={AccountPriorityEnums.D}
          activePriorities={priorities}
        />
      </Stack>
    </Stack>
  );
}

export default PriorityFilter;
