import { AccountSimilarWins } from "types/api";

export const prepareListBuilderAccounts = (similarAccounts: AccountSimilarWins[]) => {
  // clean up dependencies
  const list = {} as any
  for (let similarAccount of similarAccounts) {
    const withAccounts = similarAccount.accounts.filter(Boolean).map((account) => ({ ...account, parentAccount: similarAccount.name }))
    if (!withAccounts.length) continue;
    list[similarAccount.name as any] = withAccounts
  };
  return Object.values(list).flat() as AccountSimilarWins['accounts'] & { parentAccount: string }
}