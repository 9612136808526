import envConfig from "config/envConfig";
import {
  ActivitySearchResponse, ActivitySearchResponseV2, IActivity, IActivityV2,
} from "types/api/activities";
import { AxiosNode } from "utils/axios";

export type ActivitySegment = "sales" | "customer"
interface IActivitiesSearchParams {
  offset: number;
  limit: number;
  accountIds?: string[];
  categoryFilter?: string;
  actionFilters?: string[];
  searchQuery?: string;
  userIds: string[];
  activitiesAfterDate: Date;
  activityGroupId?: string;
  dismissedActivities?: { accountIds: string[]; activityIds: string[] };
  initiatedBy?: ActivitySegment;
}

function mapActivityFromV2ToMain(activity: IActivityV2): IActivity {
  return {
    id: activity.properties.id,
    category: activity.properties.category,
    status: activity.properties.customFields.status,
    oldStatus: activity.properties.oldStatus,
    action: activity.properties.action,
    objectType: activity.properties.objectType,
    objectId: activity.properties.objectId,
    title: activity.properties.customFields.title,
    amount: activity.properties.customFields.amount,
    dateOfActivity: activity.properties.timestamps.activity,
    dateCreated: activity.properties.timestamps.created,
    instanceId: activity.instanceId,
    subject: activity.properties.subject,
    scope: activity.properties.customFields.scope,
    body: activity.properties.customFields.body,
    description: activity.properties.customFields.description,
    direction: activity.properties.customFields.direction,
    activityGroupId: activity.properties.activityGroupId,
    isCompleted: activity.properties.customFields.isCompleted,
    type: activity.properties.customFields.type,
    sentiment: activity.properties.customFields.sentiment,
    duration: activity.properties.customFields.duration,
    newBuyingStage: activity.properties.customFields.newBuyingStage,
    oldBuyingStage: activity.properties.customFields.oldBuyingStage,
    newIntentScore: activity.properties.customFields.newIntentScore,
    oldIntentScore: activity.properties.customFields.oldIntentScore,
    disposition: activity.properties.customFields.disposition,
    rid: activity.rid,
    leadSource: activity.properties.customFields.leadSource,
    source: activity.properties.firstParty,
    secondParty: activity.properties.secondParty,
    subType: activity.properties.subType,
    sender: activity.properties.customFields.sender,
    recipient: activity.properties.customFields.recipient || [],
    campaignName: activity.properties.customFields.campaignName,
    campaignSource: activity.properties.customFields.campaignSource,
    location: activity.properties.customFields.location,
    meetingStartTime: activity.properties.customFields.meetingStartTime,
    meetingEndTime: activity.properties.customFields.meetingEndTime,
    meetingLinks: activity.properties.customFields.meetingLinks,
    visitedLink: activity.properties.customFields.visitedLink,
  } as IActivity;
}

const search = async (payload: Partial<IActivitiesSearchParams>, options?: Parameters<typeof AxiosNode.post>[2]) => {
  try {
    payload.searchQuery = payload.searchQuery === '*' ? undefined : payload.searchQuery
    if (envConfig.apiVersion == "V1") {
      const { data } = await AxiosNode.post<ActivitySearchResponse>("/activity", payload, options);
      const { totalResultCount, activities, activityActionTypes } = data;
      return { totalResultCount, activities: activities.map((a) => a.document), activityActionTypes };
    }
    const { data } = await AxiosNode.post<ActivitySearchResponseV2>("/activity", payload, options);
    const { totalResultCount, activities, activityActionTypes } = data;
    return { totalResultCount, activities: activities.map((a) => mapActivityFromV2ToMain(a.document)), activityActionTypes };
  } catch (err: any) {
    return {
      error: err, totalResultCount: 0, activities: [], activityActionTypes: [],
    };
  }
};

export default search;
